import React from 'react'
import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { AxiosPost } from '../../../../../context/UserContext'
import { pushNotify } from '../../../../../services/NotifyService'

function PropertyMaterialLogs() {
  const initialFormData = { shoot_date: '', activity_type: '', description: '', quantity: '', remarks: '' }
  const [formData, setFormData] = useState(initialFormData)
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!Object.values(formData).every(val => {
        if (typeof (val) != 'object') {
          if (val.trim() == '') return false;
          else return true;
        }
        else {
          if (val != null) return true;
          else return false;
        }
      })) {
        pushNotify('error', 'Error', 'Please Fill in all Required Fields! in');
        return;
      }
      const data = await AxiosPost('add_property_logs.php', formData);
      if (data.success) {
        pushNotify('success', "Success", 'Log Added Successfully');
        setFormData(initialFormData);
      }
      else pushNotify('error', "Error", data.error);

    } catch (err) {
      pushNotify("error", "Error", "Server Error!");
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {
        isLoading ? <div className="row mx-0" style={{ height: "500px" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
          :
          <div className="basic-form">
            <form onSubmit={formSubmitHandler}>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Description</label>
                  <input type="text" className="form-control" name='description' id='description' onChange={onChangeHandler} value={formData.description} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Shoot Date</label>
                  <input type="date" className="form-control" name='shoot_date' id='shoot_date' onChange={onChangeHandler} value={formData.shoot_date} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Activity Type</label>
                  <select name="activity_type" className="form-control" id='activity_type' onChange={onChangeHandler} value={formData.activity_type} >
                    <option value="Shoot">Shoot</option>
                    <option value="Non Shoot">Non Shoot</option>
                  </select>
                  {/* <input type="text" className="form-control" name='activity_type' id='activity_type' onChange={onChangeHandler} value={formData.activity_type} /> */}
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Quantity</label>
                  <input type="number" className="form-control" name='quantity' id='quantity' onChange={onChangeHandler} value={formData.quantity} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Remarks</label>
                  <input type="text" className="form-control" name='remarks' id='remarks' onChange={onChangeHandler} value={formData.remarks} />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Add Log
              </button>
            </form>
          </div>
      }
    </>
  )
}

export default PropertyMaterialLogs