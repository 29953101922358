import React, { Fragment, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { DateTime } from "luxon";
import { Axios, AxiosPost } from "../../../../context/UserContext";
import { pushNotify } from "../../../../services/NotifyService";
import { Modal, Button } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

function TransTable(props) {
  const [showTransComponent,setTransComponent]=useState(false);
  const [selectedBillId,setSelectedBillId]=useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState({ value: "All", label: "All" });
  const [typeFilter, setTypeFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState([undefined, undefined]);
  const [settleBill, setSettleBill] = useState({ show: false, bill_id: null, settled_amount: null });
  const [detailsModal, setDetailsModal] = useState(false);
  const [billInfo, setBillInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const statusOptions = [
    { value: "All", label: "All" },
    { value: localStorage.userId,label: "Raised by Me"},
    { value: "Settled", label: "Settled" },
    { value: "Raised", label: "Raised" },
    { value: "Approved", label: "Approved" },
    { value: "Held", label: "Held" }
  ]
  const typeOptions = [
    { value: 0, label: "Property & Material Hire" },
    { value: 1, label: "Equipment Hire" },
    { value: 2, label: "Generator Hire" },
    { value: 3, label: "Ground Transportation" },
    { value: 4, label: "Salary" },
  ]

  const billenum = ["Property & material hire", "Equipment Hire", "Generator Hire", "Ground Transportation Hire", "Salary"]

  const resetFilters = () => {
    setSearchFilter("");
    setStatusFilter({ value: "All", label: "All" });
    setTypeFilter(null);
    setDateFilter([undefined, undefined]);
  }

  const filterTransactions = (transaction) => {
    if (!transaction.bill_id.includes(searchFilter)) return false;
    if(statusFilter.value==localStorage.userId && !transaction.raised_by.includes(localStorage.userId))return false;
    if (statusFilter.value != localStorage.userId && statusFilter.value != "All" && !transaction.bill_status.includes(statusFilter.value)) return false;
    if (typeFilter != null && typeFilter.value != transaction.billType) return false;
    if (dateFilter != null && dateFilter[0] != undefined && dateFilter[1] != undefined) {
      var start = DateTime.fromJSDate(dateFilter[0]);
      var end = DateTime.fromJSDate(dateFilter[1]);
      var bill_date = DateTime.fromSQL(transaction.raised_datetime);
      if (start > bill_date || bill_date > end) return false;
    }
    return true;
  }

  const settleTrans = (id) => {
    setSettleBill({ show: true, bill_id: id });
  }

  const cancelSettleTrans = () => {
    setSettleBill({ show: false, bill_id: null, bill_amount: null });
  }

  const handleSettleTrans = async () => {
    setIsLoading(true);
    if (settleBill.settled_amount == null) {
      pushNotify("error", "Error", "Please enter valid settled amount.")
      setIsLoading(false);
      return;
    }
    try {
      const data = await AxiosPost('settle_trans.php', { "bill_id": settleBill.bill_id, "settled_amount": settleBill.settled_amount });
      if (data.success) {
        setSettleBill({ show: false, bill_id: null, bill_amount: null })
        console.log(data)
        pushNotify("success", "Success", "Bill Settled successfully")
        props.fetchTransactionsData();
      }
      else {
        pushNotify("error", "Error", data.error)
      }

    }
    catch (err) {
      pushNotify("error", "Error", "Server Error!")
    }
    finally {
      setIsLoading(false);
    }
  }

  const holdTrans = async (id) => {
    setIsLoading(true);
    try {
      const data = await AxiosPost('hold_trans.php', { "bill_id": id });
      if (data.success) {
        pushNotify('success', "Success", "Bill Held Successfully")
        props.fetchTransactionsData();
      }
      else pushNotify('error', "Error", data.error)
    }
    catch (err) {
      pushNotify('error', "Error", "Server Error!")
    }
    finally {
      setIsLoading(false);
    }
  }

  const showBillDetails = async (id) => {
    setIsLoading2(true);
    try {
      setDetailsModal(true);
      const data = await AxiosPost('get_trans_info.php', { "bill_id": id })
      if (data.success) {
        console.log(data);
        setBillInfo(data);
      }
      else pushNotify('error', "Error", data.error)
    }
    catch (err) {
      pushNotify('error', "Error", "Server Error!")
    }
    finally {
      setIsLoading2(false)
    }
  }

  return (
    <Fragment>
      <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-body row">
            <div className="col-md-3">
              <input type="text" className="form-control" placeholder="Search by Bill ID" value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
            </div>
            <div className="col-md-2">
              <Select
                placeholder="Search by Bill Status"
                value={statusFilter}
                onChange={setStatusFilter}
                options={statusOptions}
              />
            </div>
            <div className="col-md-3">
              <DateRangePicker appearance="default" placeholder="Search between Dates" style={{ width: "100%" }} value={dateFilter} onChange={(e) => setDateFilter(e)} />
            </div>
            <div className="col-md-3">
              <Select
                placeholder="Search by Bill Type"
                value={typeFilter}
                onChange={setTypeFilter}
                options={typeOptions}
              />
            </div>
            <button className="btn btn-success col-md-1" onClick={resetFilters} style={{ "width": "fit-content", "padding": "3px 10px", "borderRadius": "5px" }}><i class="fas fa-redo"></i></button>
          </div>
        </div>
      </div>
      <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Transactions<button className='btn btn-refresh' onClick={props.fetchTransactionsData}><i class="fas fa-sync-alt"></i></button></h4>
          </div>
          <div className="card-body" style={{ "height": "600px" }}>
            <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
              {
                (props.transactionsData == null || props.isLoading || isLoading) ?
                  <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                  (<table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Bill Id</th>
                        <th scope="col">Bill Type</th>
                        <th scope="col">Vendor Name</th>
                        <th scope="col">Bill Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.transactionsData.transactions.filter(transaction => filterTransactions(transaction)).map(transaction => (
                        <tr>
                          <td>{transaction.bill_id}</td>
                          <td>
                            {(transaction.billType == '0') ? "Propery & Material Hire" : (transaction.billType == '1') ? "Equipment Hire" : (transaction.billType == '2') ? "Generator Hire" : (transaction.billType == '3') ? "Ground Transportation Hire" : (transaction.billType == '4') ? "Salary" : (transaction.billType == '5') ? "Food Expense" : null}
                          </td>
                          <td>{transaction.user_id}</td>
                          <td>{transaction.bill_amount}</td>
                          <td>
                            {(transaction.bill_status == "Raised") ?
                              (<span className="badge badge-rounded badge-primary">{transaction.bill_status}</span>) :
                              (transaction.bill_status == "Settled") ?
                                (<span className="badge badge-rounded badge-success">{transaction.bill_status}</span>) :
                                (transaction.bill_status == "Held") ?
                                  (<span className="badge badge-rounded badge-danger">{transaction.bill_status}</span>) :
                                  (transaction.bill_status == "Approved") ?
                                    (<span className="badge badge-rounded badge-warning">{transaction.bill_status}</span>) :
                                    null
                            }

                          </td>
                          <td>
                            <button class="btn btn-primary tp-btn sharp" onClick={()=>{props.setVendorId(transaction.vendor_id);props.setSelectedBillId(transaction.bill_id);props.setTransComponent(true);}}><i class="fas fa-arrow-right"></i></button>
                            {/* <Dropdown className="dropdown custom-dropdown mb-0">
                              <Dropdown.Toggle className="btn sharp btn-primary tp-btn i-false" data-toggle="dropdown">
                                <i class="fas fa-ellipsis-v"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                {transaction.bill_status == "Raised" ? (<Dropdown.Item className="dropdown-item" onClick={() => approveTrans(transaction.bill_id)}>Approve</Dropdown.Item>) : null}
                                {(transaction.bill_status == "Raised" || transaction.bill_status == "Approved") ? (<Dropdown.Item className="dropdown-item" onClick={() => holdTrans(transaction.bill_id)}>Hold</Dropdown.Item>) : null}
                                {transaction.bill_status == "Approved" ? (<Dropdown.Item className="dropdown-item" onClick={() => settleTrans(transaction.bill_id)}>Settle</Dropdown.Item>) : null}
                                {transaction.bill_status == "Held" ? (<Dropdown.Item className="dropdown-item" onClick={() => approveTrans(transaction.bill_id)}>Approve</Dropdown.Item>) : null}
                                <Dropdown.Item className="dropdown-item" onClick={() => showBillDetails(transaction.bill_id)}>
                                  Details
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>)
              }
            </div>
          </div>
        </div>
      </div>
      <Modal className="fade" show={settleBill.show} centered>
        <Modal.Header>
          <Modal.Title>Settle Transaction</Modal.Title>
          <Button onClick={cancelSettleTrans} variant="" className="btn-close"></Button>
        </Modal.Header>
        <Modal.Body>
          <label>Settled Amount</label>
          <input type="number" className="form-control" placeholder="Enter amount" name="settled_amount" value={settleBill.settled_amount} onChange={(e) => setSettleBill({ ...settleBill, "settled_amount": e.target.value })} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSettleTrans} variant="success light">Confirm</Button>
          <Button variant="primary" onClick={cancelSettleTrans}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal className="fade" size="xl" show={detailsModal} centered>
        <Modal.Header>
          <Modal.Title>Detailed Transaction Info</Modal.Title>
          <Button onClick={() => setDetailsModal(false)} variant="" className="btn-close"></Button>
        </Modal.Header>
        <Modal.Body>
          {(billInfo == null || isLoading2) ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> : (
            <div className="row">
              <div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
                <table className="table verticle-middle">
                  <tr><td><b>Bill Id </b></td><td>: {billInfo.transaction_info.bill_id}</td></tr>
                  <tr><td><b>Bill Type </b></td><td>: {billenum[billInfo.transaction_info.billType]}</td></tr>
                  <tr><td><b>Bill Amount </b></td><td>: {billInfo.transaction_info.bill_amount}</td></tr>
                  <tr><td><b>Approved Amount </b></td><td>: {billInfo.transaction_info.approved_amount}</td></tr>
                  <tr><td><b>Settled Amount </b></td><td>: {billInfo.transaction_info.settled_amount}</td></tr>
                  <tr><td><b>Vendor Name </b></td><td>: {billInfo.vendor_username}</td></tr>
                  <tr><td><b>Raised By </b></td><td>: {billInfo.raisedby_username}</td></tr>
                  <tr><td><b>Approved By </b></td><td>: {billInfo.approvedby_username}</td></tr>
                  <tr><td><b>Held By </b></td><td>: {billInfo.heldby_username}</td></tr>
                  <tr><td><b>Settled By </b></td><td>: {billInfo.settledby_username}</td></tr>
                  <tr><td><b>Bill Status </b></td><td>: {billInfo.transaction_info.bill_status}</td></tr>
                  <tr><td><b>Shoot Date </b></td><td>: {billInfo.transaction_info.shoot_date}</td></tr>
                </table>
              </div>
              <div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
                <table className="table verticle-middle">
                  <tr><td><b>Raised Datetime </b></td><td>: {billInfo.transaction_info.raised_datetime}</td></tr>
                  <tr><td><b>Held Datetime </b></td><td>: {billInfo.transaction_info.held_datetime}</td></tr>
                  <tr><td><b>Approved Datetime </b></td><td>: {billInfo.transaction_info.approved_datetime}</td></tr>
                  <tr><td><b>Settled Datetime </b></td><td>: {billInfo.transaction_info.settled_datetime}</td></tr>
                  <tr><td><b>Rejected Datetime </b></td><td>: {billInfo.transaction_info.rejected_datetime}</td></tr>
                  <tr><td><b>Transaction Mode </b></td><td>: {billInfo.transaction_info.transaction_mode}</td></tr>
                  {billInfo.type_specific_info.equipment_name ? (<tr><td><b>Equipment Name </b></td><td>: {billInfo.type_specific_info.equipment_name}</td></tr>) : null}
                  {billInfo.type_specific_info.item_name ? (<tr><td><b>Item Name </b></td><td>: {billInfo.type_specific_info.item_name}</td></tr>) : null}
                  {billInfo.type_specific_info.quantity ? (<tr><td><b>Quantity </b></td><td>: {billInfo.type_specific_info.quantity}</td></tr>) : null}
                  {billInfo.type_specific_info.start_time ? (<tr><td><b>Start Time </b></td><td>: {billInfo.type_specific_info.start_time}</td></tr>) : null}
                  {billInfo.type_specific_info.end_time ? (<tr><td><b>End Time </b></td><td>: {billInfo.type_specific_info.end_time}</td></tr>) : null}
                  {billInfo.type_specific_info.call_time ? (<tr><td><b>Call Time </b></td><td>: {billInfo.type_specific_info.call_time}</td></tr>) : null}
                  {billInfo.type_specific_info.in_time ? (<tr><td><b>In Time </b></td><td>: {billInfo.type_specific_info.in_time}</td></tr>) : null}
                  {billInfo.type_specific_info.out_time ? (<tr><td><b>Start Time </b></td><td>: {billInfo.type_specific_info.out_time}</td></tr>) : null}
                  {billInfo.type_specific_info.no_of_attendants ? (<tr><td><b>No. of attendants </b></td><td>: {billInfo.type_specific_info.no_of_attendants}</td></tr>) : null}
                  {billInfo.type_specific_info.no_of_call_sheet ? (<tr><td><b>No. of Call Sheet </b></td><td>: {billInfo.type_specific_info.no_of_call_sheet}</td></tr>) : null}
                  {billInfo.type_specific_info.generator_type ? (<tr><td><b>Generator Type </b></td><td>: {billInfo.type_specific_info.generator_type}</td></tr>) : null}
                  {billInfo.type_specific_info.conveyance_charges ? (<tr><td><b>Conveyance Charges </b></td><td>: {billInfo.type_specific_info.conveyance_charges}</td></tr>) : null}
                  {billInfo.type_specific_info.agent_commission ? (<tr><td><b>Agent Commission </b></td><td>: {billInfo.type_specific_info.agent_commission}</td></tr>) : null}
                  {billInfo.type_specific_info.vehicle_number ? (<tr><td><b>Vehicle Number </b></td><td>: {billInfo.type_specific_info.vehicle_number}</td></tr>) : null}
                  {billInfo.type_specific_info.used_by_vendor_id ? (<tr><td><b>Used For </b></td><td>: {billInfo.type_specific_info.used_by_vendor_id}</td></tr>) : null}
                  {billInfo.type_specific_info.fuel_charges ? (<tr><td><b>Fuel Charges </b></td><td>: {billInfo.type_specific_info.fuel_charges}</td></tr>) : null}
                  {billInfo.type_specific_info.driver_bata ? (<tr><td><b>Driver Bata </b></td><td>: {billInfo.type_specific_info.driver_bata}</td></tr>) : null}
                  {billInfo.type_specific_info.other_charges ? (<tr><td><b>Other Charges </b></td><td>: {billInfo.type_specific_info.other_charges}</td></tr>) : null}
                  {billInfo.type_specific_info.remarks ? (<tr><td><b>Remarks </b></td><td>: {billInfo.type_specific_info.remarks}</td></tr>) : null}
                </table></div>

            </div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default TransTable;