import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DateTime } from "luxon";
import { ScaleLoader } from "react-spinners";
import { Modal, Button } from "react-bootstrap";
import { AxiosGet, AxiosPost } from "../../../../../context/UserContext";
import { pushNotify } from "../../../../../services/NotifyService";
import { useEffect } from "react";

function GeneratorLogsTable(props) {
    const [propertySearchKey, setPropertySearchKey] = useState("");
    const [isLoading,setIsLoading]=useState(false);
	const [deleteModal, setDeleteModal] = useState({ show: false, id: null });

    const fetchLogsData = async()=>{
        setIsLoading(true);
        await props.fetchGeneratorLogsData();
        setIsLoading(false)
    }

    const showDeleteForm = (index) => {
		setDeleteModal({ show: true, id: index });
	}

	const deleteItem = async () => {
		setIsLoading(true);
		try {
			const data =await AxiosPost('delete_generator_log.php',{sl:deleteModal.id})
			console.log(data);
			if (data.success) pushNotify('success', 'Success', 'Item Deleted Successfully');
			else pushNotify('error', 'Error', data.error);
			setDeleteModal({ show: false, id: null });
            await props.fetchGeneratorLogsData();
			console.log(data);
		} catch (err) {
			pushNotify("error", "Error", "Server Error!");
			console.log(err)
		}
		finally{
			setIsLoading(false);
		}
	}

	const cancelDelete = () => {
		setDeleteModal({ show: false, id: null });
	}

    return (
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Generator Logs<button className='btn btn-refresh' onClick={fetchLogsData}><i class="fas fa-sync-alt"></i></button></h4>
                    <div className="header__search ml-auto">
                        <input type="text" className="header__input" placeholder="Search" onChange={(e) => { setPropertySearchKey(e.target.value) }} />
                        <i class="fas fa-search" ></i>
                    </div>
                </div>
                <div className="card-body" style={{ "height": "500px" }}>
                    <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                        {(props.generatorLogs == null || isLoading) ? <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                            <table className="table verticle-middle table-responsive-md">
                                <thead>
                                    <tr>
                                        <th scope="col">Generator Type</th>
                                        <th scope="col">Shoot Date</th>
                                        <th scope="col">Start Time</th>
                                        <th scope="col">End Time</th>
                                        <th scope="col">Number of Hours</th>
                                        <th scope="col">Remarks</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.generatorLogs.logs.filter(log => log.generator_type.includes(propertySearchKey)).map((log, i) => (
                                        <tr key={i}>
                                            <td>{log.generator_type}</td>
                                            <td>{DateTime.fromSQL(log.shoot_date).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>{log.start_time}</td>
                                            <td>{log.end_time}</td>
                                            <td>{log.no_of_hours}</td>
                                            <td>{log.remarks}</td>
                                            <td>
                                                <Dropdown className="dropdown custom-dropdown mb-0">
                                                    <Dropdown.Toggle className="btn sharp btn-primary tp-btn i-false" data-toggle="dropdown">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        <Dropdown.Item className="dropdown-item text-danger"  onClick={() =>showDeleteForm(log.sl)}>
                                                            Delete Log
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="dropdown-item">
                                                            Cancel
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
			<Modal className="fade" show={deleteModal.show} centered>
				<Modal.Header>
					<Modal.Title>Delete Log?</Modal.Title>
					<Button
						onClick={cancelDelete}
						variant=""
						className="btn-close"
					>
					</Button>
				</Modal.Header>
				<Modal.Body>
					<h4>Are you sure you want to delete the Log?</h4>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={deleteItem}
						variant="danger light"
					>
						Delete
					</Button>
					<Button variant="primary" onClick={cancelDelete}>Cancel</Button>
				</Modal.Footer>
			</Modal>
        </div>
    );
}
export default GeneratorLogsTable;