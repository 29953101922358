import React from 'react'
import { useState } from 'react'
import { Axios } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import { ScaleLoader } from 'react-spinners';

function CreateMovie(props) {
  const initialFormData = {
    movie_name: '', exe_prod_username: '', exe_prod_email: '', prod_username: '', prod_email: '', line_prod_username: '',
    line_prod_email: '', dir_username: '', dir_email: '', auditor_username: '', auditor_email: '', create_auditor: false
  }
  const [formData, setFormData] = useState(initialFormData);
  const [showAuditor, setShotAuditor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
      setIsLoading(true);
      const { data } = await Axios.post("create_movie.php", formData)
      if (data.success) {
        pushNotify("success", "Success", "Movie created successfully.")
        await props.getMovies();
      }
      else {
        pushNotify("error", "Error", data.error)
      }
    }
    catch (err) {
      pushNotify("error", "Error", "Server Error!")
    }
    finally{
      setIsLoading(false);
    }
  }

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (
    <div className='col-md-6'>
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="card-title">Create Movie</h4>
        </div>
        <div className="card-body">
          {isLoading ? <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
            :
            <div className="basic-form">
              <form onSubmit={formSubmitHandler}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Movie Name</label>
                    <input type="text" className="form-control" name='movie_name' id='movie_name' onChange={onChangeHandler} value={formData.movie_name} required />
                  </div>
                  <div className='col-md-6'></div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Executive Producer Email</label>
                    <input type="email" className="form-control" name='exe_prod_email' id='exe_prod_email' onChange={onChangeHandler} value={formData.exe_prod_email} required />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Executive Producer Username</label>
                    <input type="text" className="form-control" name='exe_prod_username' id='exe_prod_username' onChange={onChangeHandler} value={formData.exe_prod_username} required />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Producer Email</label>
                    <input type="email" className="form-control" name='prod_email' id='prod_email' onChange={onChangeHandler} value={formData.prod_email} required />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Producer Username</label>
                    <input type="text" className="form-control" name='prod_username' id='prod_username' onChange={onChangeHandler} value={formData.prod_username} required />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Line Producer Email</label>
                    <input type="email" className="form-control" name='line_prod_email' id='line_prod_email' onChange={onChangeHandler} value={formData.line_prod_email} required />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Line Producer Username</label>
                    <input type="text" className="form-control" name='line_prod_username' id='line_prod_username' onChange={onChangeHandler} value={formData.line_prod_username} required />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Director Email</label>
                    <input type="email" className="form-control" name='dir_email' id='dir_email' onChange={onChangeHandler} value={formData.dir_email} required />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Director Username</label>
                    <input type="text" className="form-control" name='dir_username' id='dir_username' onChange={onChangeHandler} value={formData.dir_username} required />
                  </div>
                  <div className="form-group mb-3 col-md-12">
                    <input type="checkbox" name="auditorStatus" id="auditorStatus" onChange={(e) => { setShotAuditor(!showAuditor); setFormData({ ...formData, create_auditor: !formData.create_auditor }) }} />
                    <label style={{ paddingLeft: "10px" }}> Create Auditor ?</label>
                  </div>
                  {
                    showAuditor ? (
                      <>
                        <div className="form-group mb-3 col-md-6">
                          <label>Auditor Email</label>
                          <input type="email" className="form-control" name='auditor_email' id='auditor_email' onChange={onChangeHandler} value={formData.auditor_email} />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label>Auditor Username</label>
                          <input type="text" className="form-control" name='auditor_username' id='auditor_username' onChange={onChangeHandler} value={formData.auditor_username} />
                        </div>
                      </>
                    ) : null
                  }

                </div>
                <button type="submit" className="btn btn-primary">
                  Create Movie
                </button>
              </form>
            </div>
          }

        </div>
      </div>


    </div>
  )
}

export default CreateMovie