import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { pushNotify } from "../../../../services/NotifyService";
import { Axios, AxiosPost } from "../../../../context/UserContext";
import { ScaleLoader } from 'react-spinners';

const Contacts = (props) => {
	const [activeAccountsKey, setActiveAccountsKey] = useState('');
	const [deactiveAccountsKey, setDeActiveAccountsKey] = useState('');
	const [accountInfo, setAccountInfo] = useState(null);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [isLoading,setIsLoading]=useState(false);

	const banAccount = async (id) => {
		try {
			const data = await AxiosPost('ban_account.php', { "vendor_id": id });
			if (data.success) {
				pushNotify("success", "Success", "Account Banned Successfully. The account can be unbanned from the deactive accounts section.");
				props.fetchAccountsData();
			}
			else pushNotify("error", "Error", data.error)
		}
		catch (err) {
			pushNotify("error", "Error", "Server Error!");
			console.log(err);
		}
	}
	const unbanAccount = async (id) => {
		try {
			const data = await AxiosPost('unban_account.php', { "vendor_id": id });
			if (data.success) {
				pushNotify("success", "Success", "Account UnBanned Successfully.");
				props.fetchAccountsData();
			}
			else pushNotify("error", "Error", data.error)
		}
		catch (err) {
			pushNotify("error", "Error", "Server Error!");
			console.log(err);
		}
	}

	const getAccountInfo = async (id) => {
		setIsLoading(true);
		try {
			setShowInfoModal(true);
			const data = await AxiosPost("get_account_info.php", { "vendor_id": id });
			if (data.success) {
				console.log(data);
				setAccountInfo(data);
			} else {
				pushNotify("error", "Error", data.error);
			}
		}
		catch (err) {
			pushNotify("error", "Error", "Server Error!")
		}
		finally{
			setIsLoading(false);
		}

	}

	return (
		<>

			<div className="row">
				<div className="col-xl-6" >
					<div className='card'>
						<div className='card-header'>
							<div className='card-title'>Active Accounts<button className='btn btn-refresh' onClick={props.fetchAccountsData}><i class="fas fa-sync-alt"></i></button></div>
							<div className="header__search ml-auto">
								<input type="text" className="header__input" placeholder="Search" onChange={(e) => { setActiveAccountsKey(e.target.value) }} />
								<i class="fas fa-search" ></i>
							</div>
						</div>
						<div className='card-body' style={{ "height": "800px" }}>
							<div className="table-responsive recentOrderTable row" style={{ height: "100%" }}>
								{(props.accountsData == null || props.isLoading)? <ScaleLoader cssOverride={{"display":"flex","justifyContent":"center","alignItems":"center"}} /> :
								props.accountsData.accounts.filter(account => account.user_id.includes(activeAccountsKey)).filter(account => account.account_status == 1).map((account, index) => (
									<div className="col-md-6 col-sm-12 items" key={index}>
										<div className="card contact-bx item-content" style={{ height: "fit-content" }}>
											<div className="card-header border-0">
											</div>
											<div className="card-body user-profile">
												<div className="image-bx">
													<img src={"https://ui-avatars.com/api/?name=" + account.user_id + "&background=random&color=000000&format=svg"} alt="" className="rounded-circle" />
												</div>
												<div className="media-body user-meta-info">
													<h6 className="fs-18 font-w600 my-1">{account.user_id}</h6>
													<p className="fs-14 mb-3 user-work">{account.role_value}</p>
													<ul>
														<li><Link to={"#"} onClick={() => banAccount(account.vendor_id)}><i class="fas fa-ban"></i></Link></li>
														<li><Link to={"#"} onClick={() => getAccountInfo(account.vendor_id)}><i class="fas fa-info"></i></Link></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>

				</div>
				<div className="col-xl-6" >
					<div className='card'>
						<div className='card-header'>
							<div className='card-title'>DeActive Accounts<button className='btn btn-refresh' onClick={props.fetchAccountsData}><i class="fas fa-sync-alt"></i></button></div>
							<div className="header__search ml-auto">
								<input type="text" className="header__input" placeholder="Search" onChange={(e) => { setDeActiveAccountsKey(e.target.value) }} />
								<i class="fas fa-search" ></i>
							</div>
						</div>
						<div className='card-body' style={{ "height": "800px" }}>
							<div className="table-responsive recentOrderTable row" style={{ height: "100%" }}>
								{(props.accountsData == null || props.isLoading) ? <ScaleLoader cssOverride={{"display":"flex","justifyContent":"center","alignItems":"center"}} /> : props.accountsData.accounts.filter(account => account.user_id.includes(deactiveAccountsKey)).filter(account => account.account_status != 1).map((account, index) => (
									<div className="col-md-6 col-sm-12 items" key={index}>
										<div className="card contact-bx item-content" style={{ height: "fit-content" }}>
											<div className="card-header border-0">
											</div>
											<div className="card-body user-profile">
												<div className="image-bx">
													<img src={"https://ui-avatars.com/api/?name=" + account.user_id + "&background=random&color=000000&format=svg"} alt="" className="rounded-circle" />
												</div>
												<div className="media-body user-meta-info">
													<h6 className="fs-18 font-w600 my-1">{account.user_id}</h6>
													<p className="fs-14 mb-3 user-work">{account.role_value}</p>
													<ul>
														<li><Link to={"#"} onClick={() => unbanAccount(account.vendor_id)}><i class="fas fa-check"></i></Link></li>
														<li><Link to={"#"} onClick={() => getAccountInfo(account.vendor_id)}><i class="fas fa-info"></i></Link></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>

				</div>
			</div>
			<Modal className="fade" show={showInfoModal} size="xl" centered>
				<Modal.Header>
					<Modal.Title>Account Info</Modal.Title>
					<Button
						onClick={() => setShowInfoModal(false)}
						variant=""
						className="btn-close"
					>

					</Button>
				</Modal.Header>
				<Modal.Body>
					{(accountInfo == null||isLoading) ? <ScaleLoader cssOverride={{"display":"flex","justifyContent":"center","alignItems":"center"}} /> :
						(
							<div className="row">
								<div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
									<table className="table verticle-middle">
										<tr><td><b>First Name </b></td><td>: {accountInfo.info[0].first_name}</td></tr>
										<tr><td><b>Last Name </b></td><td>: {accountInfo.info[0].last_name}</td></tr>
										<tr><td><b>Vendor Name </b></td><td>: {accountInfo.info[0].vendor_name}</td></tr>
										<tr><td><b>Vendor Type </b></td><td>: {accountInfo.info[0].type}</td></tr>
										<tr><td><b>PAN Number </b></td><td>: {accountInfo.info[0].pan}</td></tr>
										<tr><td><b>GST Number </b></td><td>: {accountInfo.info[0].gst}</td></tr>
										<tr><td><b>Aadhar Number </b></td><td>: {accountInfo.info[0].aadhar_number}</td></tr>
										<tr><td><b>KYC Verified</b></td><td>: {accountInfo.info[0].kyc_verified}</td></tr>
										<tr><td><b>Mobile Number </b></td><td>: {accountInfo.info[0].mobile_number}</td></tr>
										<tr><td><b>Mobile Number 2</b></td><td>: {accountInfo.info[0].mobile_number_other}</td></tr>
										<tr><td><b>State </b></td><td>: {accountInfo.info[0].state}</td></tr>
										<tr><td><b>Country </b></td><td>: {accountInfo.info[0].country}</td></tr>
										<tr><td><b>IFSC Code </b></td><td>: {accountInfo.info[0].ifsc}</td></tr>
										<tr><td><b>Account Number </b></td><td>: {accountInfo.info[0].account_number}</td></tr>
										<tr><td><b>Contract Type </b></td><td>: {accountInfo.info[0].contract_type}</td></tr>
									</table>
								</div>
								<div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
									<table className="table verticle-middle">
										<tr><td><b>Contract Signed </b></td><td>: {accountInfo.info[0].contract_signed}</td></tr>
										<tr><td><b>Estimated Budget </b></td><td>: {Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(accountInfo.info[0].estimated_budget)}</td></tr>
										<tr><td><b>Final Budget </b></td><td>: {Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(accountInfo.info[0].final_budget)}</td></tr>
										<tr><td><b>Borading Date </b></td><td>: {accountInfo.info[0].boarding_date}</td></tr>
										<tr><td><b>Work Rating </b></td><td>: {accountInfo.info[0].work_rating}</td></tr>
										<tr><td><b>Planned Days </b></td><td>: {accountInfo.info[0].planned_days}</td></tr>
										<tr><td><b>Utilised Days </b></td><td>: {accountInfo.info[0].utilised_days}</td></tr>
										<tr><td><b>Last Updated </b></td><td>: {accountInfo.info[0].last_updated}</td></tr>
										<tr><td><b>Role </b></td><td>: {accountInfo.info[0].role_value}</td></tr>
										<tr><td><b>Amount Paid </b></td><td>: {Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(accountInfo.info[0].actual_amount_paid)}</td></tr>
										<tr><td><b>Bank Transfer </b></td><td>: {Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(accountInfo.info[0].bank_transfer_amount)}</td></tr>
										<tr><td><b>Cash payment </b></td><td>: {Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(accountInfo.info[0].cash_transaction)}</td></tr>
										<tr><td><b>Balance Amount </b></td><td>: {Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(accountInfo.info[0].balance_amount)}</td></tr>
										<tr><td><b>Email Id </b></td><td>: {accountInfo.info[0].email}</td></tr>

									</table>
								</div>

							</div>
						)}
				</Modal.Body>
			</Modal>
		</>
	)
}
export default Contacts;