/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
//import { Dropdown } from "react-bootstrap";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { UserContext } from "../../../context/UserContext";
//import LogoutPage from './Logout';



class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = ["", "dashboard"],
    Accounts = ["Accounts"],
    VendorAccounts = ["vendor-accounts"],
    ItemMaster = ["item-master"],
    Transactions = ["transactions"],
    Schedules = ["schedules"],
    MovieDashboard=["movie-dashboard"],
    SiteLogs=["site-logs"],
    Admin = ["admin"];

  if (window.localStorage.getItem('role') == "ADM_MASTER") {
    deshBoard = ["dashboard"]
    Admin = ["", "admin"];
  }

  const {movieSelected,movieSelectedCheck}=useContext(UserContext);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    movieSelectedCheck();
    //sidebar icon Heart blast

  }, []);
  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">

        <MM className="metismenu" id="menu">
          {
            (window.localStorage.getItem("role") == "ADM_MASTER") ?
              ((window.localStorage.getItem("movie") == null) ?
                <li className={`${Admin.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/admin" className="ai-icon" >
                    <i class="fas fa-user-cog"></i>
                    <span className="nav-text">Admin</span>
                  </Link>
                </li> :
                <>
                  <li className={`${Admin.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/admin" className="ai-icon" >
                      <i class="fas fa-user-cog"></i>
                      <span className="nav-text">Admin</span>
                    </Link>
                  </li>
                  <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                    <Link to="dashboard" className="ai-icon" >
                      <i className="fas fa-home"></i>
                      <span className="nav-text">Home</span>
                    </Link>
                  </li>
                  <li className={`${Accounts.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/Accounts" className="ai-icon" >
                      <i className="fas fa-user"></i>
                      <span className="nav-text">Accounts</span>
                    </Link>
                  </li>
                  <li className={`${VendorAccounts.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/vendor-accounts" className="ai-icon" >
                      <i className="fas fa-clone"></i>
                      <span className="nav-text">Vendor Dashboard</span>
                    </Link>
                  </li>
                  <li className={`${ItemMaster.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/item-master" className="ai-icon" >
                      <i class="fas fa-box"></i>
                      <span className="nav-text">Items Master</span>
                    </Link>
                  </li>
                  <li className={`${Transactions.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/transactions" className="ai-icon" >
                      <i class="fas fa-money-check"></i>
                      <span className="nav-text">Transactions</span>
                    </Link>
                  </li>
                  <li className={`${Schedules.includes(path) ? "mm-active" : ""}`}>
                    <Link to="/schedules" className="ai-icon" ><i class="fas fa-calendar-alt"></i>
                      <span className="nav-text">Schedule</span>
                    </Link>
                  </li></>
              )
              : (<><li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                <Link to="" className="ai-icon" >
                  <i className="fas fa-home"></i>
                  <span className="nav-text">Home</span>
                </Link>
              </li>
                <li className={`${Accounts.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/Accounts" className="ai-icon" >
                    <i className="fas fa-user"></i>
                    <span className="nav-text">Accounts</span>
                  </Link>
                </li>
                <li className={`${VendorAccounts.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/vendor-accounts" className="ai-icon" >
                    <i className="fas fa-clone"></i>
                    <span className="nav-text">Vendor Dashboard</span>
                  </Link>
                </li>
                <li className={`${ItemMaster.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/item-master" className="ai-icon" >
                    <i class="fas fa-box"></i>
                    <span className="nav-text">Items Master</span>
                  </Link>
                </li>
                <li className={`${Transactions.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/transactions" className="ai-icon" >
                    <i class="fas fa-money-check"></i>
                    <span className="nav-text">Transactions</span>
                  </Link>
                </li>
                <li className={`${Schedules.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/schedules" className="ai-icon" ><i class="fas fa-calendar-alt"></i>
                    <span className="nav-text">Schedule</span>
                  </Link>
                </li>
                <li className={`${MovieDashboard.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/movie-dashboard" className="ai-icon" ><i class="fas fa-video"></i>
                    <span className="nav-text">Movie Dashboard</span>
                  </Link>
                </li>
                <li className={`${SiteLogs.includes(path) ? "mm-active" : ""}`}>
                  <Link to="/site-logs" className="ai-icon" ><i class="fas fa-clipboard-list"></i>
                    <span className="nav-text">Site Logs</span>
                  </Link>
                </li>
                </>)
          }

        </MM>
        <div className="copyright" style={{ "bottom": "0" }}>
          <p><strong>Flick Analytics</strong> © 2021 All Rights Reserved</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
