import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

/// Widget
///Accounts

import Accounts from "./components/Accounts/Accounts";


import { ThemeContext } from "../context/ThemeContext";
import VendorAccounts from "./components/VendorAccounts/VendorAccounts";
import ItemMaster from "./components/ItemMaster/ItemMaster";
import Transactions from "./components/Transactions/Transactions";
import Schedules from "./components/Schedules/Schedules";
import Admin from "./components/Admin/Admin";
import MovieDashboard from "./components/MovieDasboard/MovieDashboard";
import SiteLogs from "./components/SiteLogs/SiteLogs";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  let routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    /// Accounts
    { url: "accounts", component: Accounts },
    /// Vendor Accounts
    { url: "vendor-accounts", component: VendorAccounts },
    /// Item Master
    { url: "item-master", component: ItemMaster },
    /// Transactions
    { url: "transactions", component: Transactions },
    /// Schedules
    { url: "schedules", component: Schedules },

    { url: "admin", component: Admin },
    {url: "movie-dashboard",component: MovieDashboard},
    {url:"site-logs",component:SiteLogs}
  ];
  if (window.localStorage.getItem("role") == "ADM_MASTER") {
    routes = [
      /// Dashboard
      { url: "", component: Admin },
      { url: "dashboard", component: Home },
      /// Accounts
      { url: "accounts", component: Accounts },
      /// Vendor Accounts
      { url: "vendor-accounts", component: VendorAccounts },
      /// Item Master
      { url: "item-master", component: ItemMaster },
      /// Transactions
      { url: "transactions", component: Transactions },
      /// Schedules
      { url: "schedules", component: Schedules },

      { url: "admin", component: Admin },
      {url: "movie-dashboard",component: MovieDashboard},
      {url:"site-logs",component:SiteLogs}
    ];
  }
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </>
  );
};

export default Markup;
