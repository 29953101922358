import React, { useContext, useEffect, useState } from 'react';
import CrewLogs from './components/forms/CrewLogs';
import EquipmentLogs from './components/forms/EquipmentLogs';
import GeneratorLogs from './components/forms/GeneratorLogs';
import GroundTransportLogs from './components/forms/GroundTransportLogs';
import PropertyMaterialLogs from './components/forms/PropertyMaterialLogs';
import { Col, Card, Tab, Nav, Dropdown } from "react-bootstrap";
import { Axios, AxiosGet } from '../../../context/UserContext';
import PropertyLogsTable from './components/tables/PropertyLogsTable';
import EquipmentLogsTable from './components/tables/EquipmentLogsTable';
import GeneratorLogsTable from './components/tables/GeneratorLogsTable';
import { pushNotify } from '../../../services/NotifyService';
import TransportLogsTable from './components/tables/TransportLogsTable';
import CrewLogsTable from './components/tables/CrewLogsTable';

function SiteLogs() {
	const [vendorIdOptions, setVendorIdOptions] = useState([{ value: "-1", label: "Fetching Please wait..." }]);
    const [equipmentLogs,setEquipmentLogs]=useState(null);
    const [generatorLogs,setGeneratorLogs]=useState(null);
    const [propertyLogs,setPropertyLogs]=useState(null);
    const [transportLogs,setTransportLogs]=useState(null);
    const [crewLogs,setCrewLogs]=useState(null);

	useEffect(() => {
		fetchVendorIdOptions();
        fetchEquipmentLogsData();
        fetchGeneratorLogsData();
        fetchPropertyLogsData();
        fetchTransportLogsData();
        fetchCrewLogsData();
	}, []);

	const fetchVendorIdOptions = async () => {
		const data = await AxiosGet('get_vendorId_options.php');
		setVendorIdOptions(data.options);
	}

    const fetchCrewLogsData = async()=>{
        try{
            const data =await AxiosGet('get_crew_logs.php');
            if(data.success){
                setCrewLogs(data);
            }
            else {
                pushNotify("error","Error",data.error);
            }
        }
        catch{
            pushNotify("error","Error","Server Error!")
        }
    }

    const fetchTransportLogsData = async()=>{
        try{
            const data =await AxiosGet('get_transport_logs.php');
            if(data.success){
                setTransportLogs(data);
            }
            else {
                pushNotify("error","Error",data.error);
            }
        }
        catch{
            pushNotify("error","Error","Server Error!")
        }
    }

    const fetchEquipmentLogsData = async()=>{
        try{
            const data =await AxiosGet('get_equipment_logs.php');
            if(data.success){
                setEquipmentLogs(data);
            }
            else {
                pushNotify("error","Error",data.error);
            }
        }
        catch{
            pushNotify("error","Error","Server Error!")
        }
    }

    const fetchGeneratorLogsData = async()=>{
        try{
            const data =await AxiosGet('get_generator_logs.php');
            if(data.success){
               setGeneratorLogs(data);
            }
            else {
                pushNotify("error","Error",data.error);
            }
        }
        catch{
            pushNotify("error","Error","Server Error!")
        }
    }

    const fetchPropertyLogsData = async()=>{
        try{
            const data =await AxiosGet('get_property_logs.php');
            if(data.success){
                setPropertyLogs(data);
            }
            else {
                pushNotify("error","Error",data.error);
            }
        }
        catch{
            pushNotify("error","Error","Server Error!")
        }
    }

    const tabData = [
		{
			name: "Propery & Material",
			content: <PropertyMaterialLogs vendorIdOptions={vendorIdOptions}></PropertyMaterialLogs>,
		},
		{
			name: "Equipment",
			content: <EquipmentLogs vendorIdOptions={vendorIdOptions}></EquipmentLogs>,
		},
		{
			name: "Generator",
			content: <GeneratorLogs vendorIdOptions={vendorIdOptions}></GeneratorLogs>,
		},

		{
			name: "Ground Transportation",
			content: <GroundTransportLogs vendorIdOptions={vendorIdOptions}></GroundTransportLogs>,
		},
		{
			name: "Crew Log",
			content: <CrewLogs vendorIdOptions={vendorIdOptions}></CrewLogs>,
		}
	];

    return (
        <div className="col-xl-12">
            <div className="row">
                
        <div className="col-lg-12 col-md-12 col-sm-12">
                <Card>
                    <Card.Header className='border-0 pb-0' >
                        <Card.Title>Add Logs</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {/* <!-- Nav tabs --> */}
                        <div className="default-tab">
                            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                                <Nav as="ul" className="nav-tabs">
                                    {tabData.map((data, i) => (
                                        <Nav.Item as="li" key={i}>
                                            <Nav.Link eventKey={data.name.toLowerCase()}>
                                                {data.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                <Tab.Content className="pt-4">
                                    {tabData.map((data, i) => (
                                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                            <p>{data.content}</p>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Card.Body>
                </Card>
                
        </div>
                <PropertyLogsTable fetchPropertyLogsData={fetchPropertyLogsData} propertyLogs={propertyLogs}></PropertyLogsTable>
                <EquipmentLogsTable fetchEquipmentLogsData={fetchEquipmentLogsData} equipmentLogs={equipmentLogs}></EquipmentLogsTable>
                <GeneratorLogsTable fetchGeneratorLogsData={fetchGeneratorLogsData} generatorLogs={generatorLogs}></GeneratorLogsTable>
                <TransportLogsTable fetchTransportLogsData={fetchTransportLogsData} transportLogs={transportLogs}></TransportLogsTable>
                <CrewLogsTable fetchCrewLogsData={fetchCrewLogsData} crewLogs={crewLogs}></CrewLogsTable>
            </div>
        </div>
    )
}

export default SiteLogs