import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DateTime } from "luxon";
import { ScaleLoader } from "react-spinners";

function PropertyItems(props) {
    const [equipSearchKey, setEquiSearchKey] = useState("");

    return (
        <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Equipment Items<button className='btn btn-refresh' onClick={props.fetchItemMasterData}><i class="fas fa-sync-alt"></i></button></h4>
                    <div className="header__search ml-auto">
                        <input type="text" className="header__input" placeholder="Search" onChange={(e) => setEquiSearchKey(e.target.value)} />
                        <i class="fas fa-search" ></i>
                    </div>
                </div>
                <div className="card-body" style={{ "height": "600px" }}>
                    <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                        {(props.itemMasterData == null || props.isLoading) ? <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                            (<table className="table verticle-middle table-responsive-md">
                                <thead>
                                    <tr>
                                        <th scope="col">Item Name</th>
                                        <th scope="col">Date Created</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.itemMasterData.items.filter(item => item.item_type == "equipment").filter(item => item.item_name.includes(equipSearchKey)).map((item, i) => (
                                        <tr key={i}>
                                            <td>{item.item_name}</td>
                                            <td>{DateTime.fromSQL(item.created_time).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                            <td>
                                                <Dropdown className="dropdown custom-dropdown mb-0">
                                                    <Dropdown.Toggle className="btn sharp btn-primary tp-btn i-false" data-toggle="dropdown" >
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        <Dropdown.Item className="dropdown-item text-danger" onClick={() => props.showDeleteForm(item.itemId)}>
                                                            Delete Item
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="dropdown-item">
                                                            Cancel
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PropertyItems;