import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";
import { Axios, AxiosPost } from "../../../../context/UserContext";
import { pushNotify } from "../../../../services/NotifyService";
import EditModal from "./EditModal";
import InfoModal from "./InfoModal";

function AccountsTable(props) {
    const [accountInfo, setAccountInfo] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchKey,setSearchKey]=useState('')
    const [showEditModal,setShowEditModal]=useState(false);
    const [editModalData,setEditModalData]=useState(null);

    const getAccountInfo = async (id) => {
        setIsLoading(true);
        try {
            setShowInfoModal(true);
            const data = await AxiosPost("get_account_info.php", { "vendor_id": id });
            if (data.success) {
                setAccountInfo(data);
            } else {
                pushNotify("error", "Error", data.error);
            }
        }
        catch (err) {
            pushNotify("error", "Error", "Server Error!")
        }
        finally {
            setIsLoading(false);
        }

    }

    const showEditForm=async(id)=>{
        setShowEditModal(true);
        const data = await AxiosPost("get_account_info.php", { "vendor_id": id });
        setEditModalData({...data.info[0],id:id});
    }

    return (
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Vendor Accounts<button className='btn btn-refresh' onClick={props.fetchAccountsData}><i class="fas fa-sync-alt"></i></button></h4>
                    <div className="header__search ml-auto">
                        <input type="text" className="header__input" placeholder="Search by username" onChange={(e) => { setSearchKey(e.target.value) }} />
                        <i class="fas fa-search" ></i>
                    </div>
                </div>
                <div className="card-body" style={{ "height": "450px" }}>
                    <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                        {(props.accountsData == null || props.isLoading) ?
                            <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                            (<table className="table verticle-middle table-responsive-md">
                                <thead>
                                    <tr>
                                        <th scope="col">Username</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Mobile Number</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.accountsData.accounts.filter(account=>account.user_id.includes(searchKey)).map(account => (
                                        <tr>
                                            <td>{account.user_id}</td>
                                            <td>{account.email}</td>
                                            <td>{account.role_value}</td>
                                            <td>{account.mobile_number == null ? "Null" : account.mobile_number}</td>
                                            <td>
                                                <Dropdown className="dropdown custom-dropdown mb-0">
                                                    <Dropdown.Toggle className="btn sharp btn-primary tp-btn i-false" data-toggle="dropdown">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        <Dropdown.Item className="dropdown-item" onClick={() => getAccountInfo(account.vendor_id)}>
                                                            Details
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="dropdown-item" onClick={()=>showEditForm(account.vendor_id)}>
                                                            Edit
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>)
                        }
                    </div>
                </div>
            </div>
            <InfoModal setShowInfoModal={setShowInfoModal} showInfoModal={showInfoModal} accountInfo={accountInfo} isLoading={isLoading}></InfoModal>
            {editModalData==null?null:<EditModal editModalData={editModalData} setEditModalData={setEditModalData} showEditModal={showEditModal} setShowEditModal={setShowEditModal}></EditModal>}
        </div>
    );
}
export default AccountsTable;