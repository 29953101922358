import React from 'react'
import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { AxiosPost } from '../../../../../context/UserContext'
import { pushNotify } from '../../../../../services/NotifyService'

function GeneratorLogs() {
  const initialFormData = { shoot_date: '', start_time:'', end_time:'',no_of_hours:'', generator_type:'', remarks: '' }
  const [formData, setFormData] = useState(initialFormData)
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!Object.values(formData).every(val => {
        if (typeof (val) != 'object') {
          if (val.trim() == '') return false;
          else return true;
        }
        else {
          if (val != null) return true;
          else return false;
        }
      })) {
        pushNotify('error', 'Error', 'Please Fill in all Required Fields! in');
        return;
      }
      const data = await AxiosPost('add_generator_logs.php', formData);
      if (data.success) {
        pushNotify('success', "Success", 'Bill Raised Successfully');
        setFormData(initialFormData);
      }
      else pushNotify('error', "Error", data.error);

    } catch (err) {
      pushNotify("error", "Error", "Server Error!");
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {
        isLoading ? <div className="row mx-0" style={{ height: "500px" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
          :
          <div className="basic-form">
            <form onSubmit={formSubmitHandler}>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Generator Type</label>
                  <input type="text" className="form-control" name='generator_type' id='generator_type' onChange={onChangeHandler} value={formData.generator_type} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Shoot Date</label>
                  <input type="date" className="form-control" name='shoot_date' id='shoot_date' onChange={onChangeHandler} value={formData.shoot_date} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Start Time</label>
                  <input type="time" className="form-control" name='start_time' id='start_time' onChange={onChangeHandler} value={formData.start_time} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>End Time</label>
                  <input type="time" className="form-control" name='end_time' id='end_time' onChange={onChangeHandler} value={formData.end_time} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Number of Hours</label>
                  <input type="number" className="form-control" name='no_of_hours' id='no_of_hours' onChange={onChangeHandler} value={formData.no_of_hours} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Remarks</label>
                  <input type="text" className="form-control" name='remarks' id='remarks' onChange={onChangeHandler} value={formData.remarks} />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Add Log
              </button>
            </form>
          </div>
      }
    </>
  )
}

export default GeneratorLogs