import React from 'react'
import { useContext } from 'react';
import { useState,useEffect } from 'react'
import { ScaleLoader } from 'react-spinners';
import { Axios, UserContext } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';

function AllMovies(props) {
    const [isLoading,setIsLoading]=useState(false);
    const [movieFilter,setMovieFilter]=useState("");
    const {movieSelectedCheck} =useContext(UserContext);

    const selectMovie=(id)=>{
        window.localStorage.setItem("movie",id);
        movieSelectedCheck();
        pushNotify("success","Success","Movie selected Successfully");
    }

    return (
        <div className='col-md-6'>
            <div className="card">
                <div className="card-header border-0 pb-0">
                    <h4 className="card-title">All Movies</h4>
                    <div className="header__search ml-auto">
                        <input type="text" className="header__input" placeholder="Search" onChange={(e) => { setMovieFilter(e.target.value) }} />
                        <i class="fas fa-search" ></i>
                    </div>
                </div>
                <div className="card-body" style={{height:"600px"}}>
                    <div className="table-responsive recentOrderTable" style={{height:"100%"}}>
                    {
                (isLoading || props.movies==null) ?
                  <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                  (<table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Sl</th>
                        <th scope="col">Movie Name</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.movies.filter(movie=>movie.movie_name.includes(movieFilter)).map((movie,id) => (
                        <tr key={id}>
                          <td>{id+1}</td>
                          <td>{movie.movie_name}</td>
                          <td><button className='btn btn-primary' onClick={()=>selectMovie(movie.movie_id)}>Select</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>)
              }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllMovies