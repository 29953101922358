import React from 'react'
import { useState } from 'react'
import { ScaleLoader } from 'react-spinners'
import { AxiosPost } from '../../../../../context/UserContext'
import { pushNotify } from '../../../../../services/NotifyService'

function GroundTransportLogs() {
  const initialFormData = { shoot_date: '', vehicle_number: '', vehicle_description: '', user_department: '', vehicles_used: '' }
  const [formData, setFormData] = useState(initialFormData)
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!Object.values(formData).every(val => {
        if (typeof (val) != 'object') {
          if (val.trim() == '') return false;
          else return true;
        }
        else {
          if (val != null) return true;
          else return false;
        }
      })) {
        pushNotify('error', 'Error', 'Please Fill in all Required Fields! in');
        return;
      }
      const data = await AxiosPost('add_transport_logs.php', formData);
      if (data.success) {
        pushNotify('success', "Success", 'Bill Raised Successfully');
        setFormData(initialFormData);
      }
      else pushNotify('error', "Error", data.error);

    } catch (err) {
      pushNotify("error", "Error", "Server Error!");
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {
        isLoading ? <div className="row mx-0" style={{ height: "500px" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
          :
          <div className="basic-form">
            <form onSubmit={formSubmitHandler}>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Vehicle Number</label>
                  <input type="text" className="form-control" name='vehicle_number' id='vehicle_number' onChange={onChangeHandler} value={formData.vehicle_number} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Vehicle Description</label>
                  <input type="text" className="form-control" name='vehicle_description' id='vehicle_description' onChange={onChangeHandler} value={formData.vehicle_description} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Shoot Date</label>
                  <input type="date" className="form-control" name='shoot_date' id='shoot_date' onChange={onChangeHandler} value={formData.shoot_date} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>User Department</label>
                  <input type="text" className="form-control" name='user_department' id='user_department' onChange={onChangeHandler} value={formData.user_department} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Vehicles Used</label>
                  <input type="number" className="form-control" name='vehicles_used' id='vehicles_used' onChange={onChangeHandler} value={formData.vehicles_used} />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Add Log
              </button>
            </form>
          </div>
      }
    </>
  )
}

export default GroundTransportLogs