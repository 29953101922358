import React, { Fragment, useEffect, useState } from "react";
import { Axios, AxiosGet } from "../../../context/UserContext";
import { pushNotify } from "../../../services/NotifyService";
import Stats from "./components/Stats";
import TransactionPage from "./components/TransactionPage";
import TransTable from "./components/TransTable";


const Transactions = () => {
  const [showTransComponent, setTransComponent] = useState(false);
  const [selectedBillId, setSelectedBillId] = useState(null);
  const [vendorId,setVendorId]=useState(null);
  const [transactionsData, setTransactionsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchTransactionsData = async () => {
    setIsLoading(true);
    try {
      const data = await AxiosGet('get_transactions_data.php');
      if (data.success) {
        console.log(data);
        setTransactionsData(data);
      } else { pushNotify("error", "Error", data.error); console.log(data); }
    } catch (err) {
      pushNotify("error", "Error", "Server Error!")
    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchTransactionsData();
  }, [])

  return (
    <Fragment>
      {showTransComponent ?
        <TransactionPage fetchTransactionsData={fetchTransactionsData} setTransComponent={setTransComponent} vendorId={vendorId} selectedBillId={selectedBillId} setSelectedBillId={setSelectedBillId}></TransactionPage>
        :
        <>
          <Stats transactionsData={transactionsData}></Stats>
          <TransTable setTransComponent={setTransComponent} setVendorId={setVendorId} setSelectedBillId={setSelectedBillId} isLoading={isLoading} transactionsData={transactionsData} fetchTransactionsData={fetchTransactionsData}></TransTable>
        </>
      }
    </Fragment>
  );
};

export default Transactions;
