import React, { useState, useEffect } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import { Axios, AxiosGet, AxiosPost, convertNumberToWords } from "../../../../context/UserContext";
import { pushNotify } from "../../../../services/NotifyService";
import { ScaleLoader } from "react-spinners";

const vendorTypeOptions = [
  { value: "Individual", label: "individual" },
  { value: "Company", label: 'company' }
]

const decisionOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
]

const initialFormData = {
  first_name: '', last_name: '', vendor_name: '', vendor_type: null, vendor_email: "", vendor_role: null, pan: "", gst: "", aadhar_number: "", kyc_verified: null,
  mobile_number: '', mobile_number_other: '', country: '', state: '', ifsc: '', account_number: '', contract_signed: null, contract_type: '',
  estimated_budget: '', finalised_budget: '', boarding_date: '', work_rating: '', planned_days: '', days_utilised: ''
}

function RegisterVendor() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [formData, setFormData] = useState(initialFormData);
  const [roleOptions, setRoleOptions] = useState([{ value: "-1", label: "Loading..." }]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRoleOptions = async () => {
    const data = await AxiosGet('get_role_options.php');
    setRoleOptions(data.options);
  }

  const RegisterVendorHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!Object.values(formData).every(val => val !== null)) {
			pushNotify('error', 'Error', 'Please Fill in all Required Fields! in');
			setIsLoading(false);
			return;
		}
    try {
      const data = await AxiosPost('register_vendor.php', formData)
      if (data.success) {
        pushNotify("success", "Success", "Vendor Registered Successfully and credentials are sent to the respectived Email.")
        setFormData(initialFormData)
      }
      else {
        pushNotify("error", "Error", data.error)
      }
    }
    catch (err) {
      pushNotify("error", "Error", "Server Error!")
    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchRoleOptions()
  }, [])

  return (
    <div className="col-xl-12 col-lg-12">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h4 className="card-title">Register Vendor</h4>
        </div>
        <div className="card-body">
          <div className="basic-form">
            {isLoading ? <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :

              <form onSubmit={(e) => RegisterVendorHandler(e)}>
                <div className="row">
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>First Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="first_name" value={formData.first_name} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required/>
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Last Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="last_name" value={formData.last_name} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required/>
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>User Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="vendor_name" value={formData.vendor_name} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required/>
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Vendor Type <span className="text-danger">*</span></label>
                    <Select
                      defaultValue={formData.vendor_type}
                      onChange={(e) => setFormData({ ...formData, "vendor_type": e })}
                      options={vendorTypeOptions}
                    />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Vendor Email <span className="text-danger">*</span></label>
                    <input type="email" className="form-control" id="vendor_email" value={formData.vendor_email} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required/>
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Vendor Role <span className="text-danger">*</span></label>
                    <Select
                      defaultValue={formData.vendor_role}
                      onChange={(e) => setFormData({ ...formData, "vendor_role": e })}
                      options={roleOptions}
                    />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>PAN Number</label>
                    <input type="text" className="form-control" id="pan" value={formData.pan} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>GST Number</label>
                    <input type="text" className="form-control" id="gst" value={formData.gst} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Aadhar Number</label>
                    <input type="number" className="form-control" id="aadhar_number" value={formData.aadhar_number} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>KYC Verified <span className="text-danger">*</span></label>
                    <Select
                      defaultValue={formData.kyc_verified}
                      onChange={(e) => setFormData({ ...formData, "kyc_verified": e })}
                      options={decisionOptions}
                    />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Mobile Number <span className="text-danger">*</span></label>
                    <input type="number" className="form-control" id="mobile_number" value={formData.mobile_number} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required/>
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Other Contact Number</label>
                    <input type="number" className="form-control" id="mobile_number_other" value={formData.mobile_number_other} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Country</label>
                    <CountryDropdown
                      classes="form-control"
                      value={formData.country}
                      onChange={(val) => setFormData({ ...formData, "country": val })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>State</label>
                    <RegionDropdown
                      classes="form-control"
                      country={formData.country}
                      value={formData.state}
                      onChange={(val) => setFormData({ ...formData, "state": val })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>IFSC Code</label>
                    <input type="text" className="form-control" id="ifsc" value={formData.ifsc} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Account Number</label>
                    <input type="text" className="form-control" id="account_number" value={formData.account_number} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Contract Signed <span className="text-danger">*</span></label>
                    <Select
                      defaultValue={formData.contract_signed}
                      onChange={(e) => setFormData({ ...formData, "contract_signed": e })}
                      options={decisionOptions}
                    />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Contract Type</label>
                    <input type="text" className="form-control" id="contract_type" value={formData.contract_type} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Estimated Budget</label>
                    <input type="number" className="form-control" id="estimated_budget" value={formData.estimated_budget} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.estimated_budget)}</div>
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Finalised Budget</label>
                    <input type="number" className="form-control" id="finalised_budget" value={formData.finalised_budget} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.finalised_budget)}</div>
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Boarding Date</label>
                    <input type="date" className="form-control" id="boarding_date" value={formData.boarding_date} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Work Rating</label>
                    <input type="text" className="form-control" id="work_rating" value={formData.work_rating} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Planned Days</label>
                    <input type="number" className="form-control" id="planned_days" value={formData.planned_days} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                  <div className="form-group mb-3 col-xl-4 col-md-6">
                    <label>Days Utilised</label>
                    <input type="number" className="form-control" id="days_utilised" value={formData.days_utilised} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Register Vendor
                </button>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
export default RegisterVendor;