import React from 'react'

function TransactionInfo(props) {
    const billenum = ["Property & material hire", "Equipment Hire", "Generator Hire", "Ground Transportation Hire", "Salary","Food Expense"]

    return (
        <div className="row">
            <div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
                <table className="table verticle-middle">
                    <tr><td><b>Bill Id </b></td><td>: {props.billInfo.transaction_info.bill_id}</td></tr>
                    <tr><td><b>Bill Type </b></td><td>: {billenum[props.billInfo.transaction_info.billType]}</td></tr>
                    <tr><td><b>Bill Amount </b></td><td>: {props.billInfo.transaction_info.bill_amount}</td></tr>
                    <tr><td><b>Approved Amount </b></td><td>: {props.billInfo.transaction_info.approved_amount}</td></tr>
                    <tr><td><b>Settled Amount </b></td><td>: {props.billInfo.transaction_info.settled_amount}</td></tr>
                    <tr><td><b>Vendor Name </b></td><td>: {props.billInfo.vendor_username}</td></tr>
                    <tr><td><b>Raised By </b></td><td>: {props.billInfo.raisedby_username}</td></tr>
                    <tr><td><b>Approved By </b></td><td>: {props.billInfo.approvedby_username}</td></tr>
                    <tr><td><b>Held By </b></td><td>: {props.billInfo.heldby_username}</td></tr>
                    <tr><td><b>Settled By </b></td><td>: {props.billInfo.settledby_username}</td></tr>
                    <tr><td><b>Bill Status </b></td><td>: {props.billInfo.transaction_info.bill_status}</td></tr>
                    <tr><td><b>Shoot Date </b></td><td>: {props.billInfo.transaction_info.shoot_date}</td></tr>
                </table>
            </div>
            <div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
                <table className="table verticle-middle">
                    <tr><td><b>Raised Datetime </b></td><td>: {props.billInfo.transaction_info.raised_datetime}</td></tr>
                    <tr><td><b>Held Datetime </b></td><td>: {props.billInfo.transaction_info.held_datetime}</td></tr>
                    <tr><td><b>Approved Datetime </b></td><td>: {props.billInfo.transaction_info.approved_datetime}</td></tr>
                    <tr><td><b>Settled Datetime </b></td><td>: {props.billInfo.transaction_info.settled_datetime}</td></tr>
                    <tr><td><b>Rejected Datetime </b></td><td>: {props.billInfo.transaction_info.rejected_datetime}</td></tr>
                    <tr><td><b>Transaction Mode </b></td><td>: {props.billInfo.transaction_info.transaction_mode}</td></tr>
                    {props.billInfo.type_specific_info.equipment_name ? (<tr><td><b>Equipment Name </b></td><td>: {props.billInfo.type_specific_info.equipment_name}</td></tr>) : null}
                    {props.billInfo.type_specific_info.item_name ? (<tr><td><b>Item Name </b></td><td>: {props.billInfo.type_specific_info.item_name}</td></tr>) : null}
                    {props.billInfo.type_specific_info.quantity ? (<tr><td><b>Quantity </b></td><td>: {props.billInfo.type_specific_info.quantity}</td></tr>) : null}
                    {props.billInfo.type_specific_info.start_time ? (<tr><td><b>Start Time </b></td><td>: {props.billInfo.type_specific_info.start_time}</td></tr>) : null}
                    {props.billInfo.type_specific_info.end_time ? (<tr><td><b>End Time </b></td><td>: {props.billInfo.type_specific_info.end_time}</td></tr>) : null}
                    {props.billInfo.type_specific_info.call_time ? (<tr><td><b>Call Time </b></td><td>: {props.billInfo.type_specific_info.call_time}</td></tr>) : null}
                    {props.billInfo.type_specific_info.in_time ? (<tr><td><b>In Time </b></td><td>: {props.billInfo.type_specific_info.in_time}</td></tr>) : null}
                    {props.billInfo.type_specific_info.out_time ? (<tr><td><b>Start Time </b></td><td>: {props.billInfo.type_specific_info.out_time}</td></tr>) : null}
                    {props.billInfo.type_specific_info.no_of_attendants ? (<tr><td><b>No. of attendants </b></td><td>: {props.billInfo.type_specific_info.no_of_attendants}</td></tr>) : null}
                    {props.billInfo.type_specific_info.no_of_call_sheet ? (<tr><td><b>No. of Call Sheet </b></td><td>: {props.billInfo.type_specific_info.no_of_call_sheet}</td></tr>) : null}
                    {props.billInfo.type_specific_info.generator_type ? (<tr><td><b>Generator Type </b></td><td>: {props.billInfo.type_specific_info.generator_type}</td></tr>) : null}
                    {props.billInfo.type_specific_info.conveyance_charges ? (<tr><td><b>Conveyance Charges </b></td><td>: {props.billInfo.type_specific_info.conveyance_charges}</td></tr>) : null}
                    {props.billInfo.type_specific_info.agent_commission ? (<tr><td><b>Agent Commission </b></td><td>: {props.billInfo.type_specific_info.agent_commission}</td></tr>) : null}
                    {props.billInfo.type_specific_info.vehicle_number ? (<tr><td><b>Vehicle Number </b></td><td>: {props.billInfo.type_specific_info.vehicle_number}</td></tr>) : null}
                    {props.billInfo.type_specific_info.used_by_vendor_id ? (<tr><td><b>Used For </b></td><td>: {props.billInfo.type_specific_info.used_by_vendor_id}</td></tr>) : null}
                    {props.billInfo.type_specific_info.fuel_charges ? (<tr><td><b>Fuel Charges </b></td><td>: {props.billInfo.type_specific_info.fuel_charges}</td></tr>) : null}
                    {props.billInfo.type_specific_info.driver_bata ? (<tr><td><b>Driver Bata </b></td><td>: {props.billInfo.type_specific_info.driver_bata}</td></tr>) : null}
                    {props.billInfo.type_specific_info.other_charges ? (<tr><td><b>Other Charges </b></td><td>: {props.billInfo.type_specific_info.other_charges}</td></tr>) : null}
                    {props.billInfo.type_specific_info.remarks ? (<tr><td><b>Remarks </b></td><td>: {props.billInfo.type_specific_info.remarks}</td></tr>) : null}
                </table></div>

        </div>
    )
}

export default TransactionInfo