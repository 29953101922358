import React, { Fragment } from "react";

function Stats(props) {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-primary text-primary">
                <i class="fas fa-user"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Total Accounts</p>
                  <h4 className="mb-0">{props.accountsData==null?"Loading...":props.accountsData.total_accounts}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-warning text-warning">
                <i class="fas fa-user-shield"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Activated Accounts</p>
                  <h4 className="mb-0">{props.accountsData==null?"Loading...":props.accountsData.active_accounts}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body  p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-danger text-danger">
                <i class="fas fa-ban"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Deactive Accounts</p>
                  <h4 className="mb-0">{props.accountsData==null?"Loading...":props.accountsData.deactive_accounts}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-success text-success">
                  <svg
                    id="icon-database-widget"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-database"
                  >
                    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                  </svg>
                </span>
                <div className="media-body">
                  <p className="mb-1">LoggedIn as</p>
                  <h4 className="mb-0">{localStorage.getItem("username")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Fragment>
  );
}

export default Stats;