import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Axios, AxiosGet } from '../../../context/UserContext';
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Col, Card, Tab, Nav, Dropdown } from "react-bootstrap";


//Import Components
import ProjectStatisticsTab from './Charts/ProjectStatisticsTab';
import PropertyMaterialForm from './components/PropertyMaterialForm';
import EquipmentForm from './components/EquipmentForm';
import GeneratorForm from './components/GeneratorForm';
import GroundTransportationForm from './components/GroundTransportationForm';
import SalaryForm from './components/SalaryForm';
import FoodForm from './components/FoodForm';
import CashAdvanceForm from './components/CashAdvanceForm';
const CompletionApexChart = loadable(() =>
	pMinDelay(import("./Charts/CompletionApexChart"), 1000)
);
const EmailChartApex = loadable(() =>
	pMinDelay(import("./Charts/EmailChartApex"), 1000)
);


const ApexBar3 = loadable(() => pMinDelay(import("../charts/apexcharts/Bar3"), 1000));
const ApexLine = loadable(() => pMinDelay(import("../charts/apexcharts/Line5"), 1000));



const Home = () => {
	const [vendorIdOptions, setVendorIdOptions] = useState([{ value: "-1", label: "Fetching Please wait..." }]);

	useEffect(() => {
		fetchVendorIdOptions();
	}, []);

	const fetchVendorIdOptions = async () => {
		const data = await AxiosGet('get_vendorId_options.php');
		setVendorIdOptions(data.options);
	}

	const tabData = [
		{
			name: "Propery & Material",
			content: <PropertyMaterialForm vendorIdOptions={vendorIdOptions}></PropertyMaterialForm>,
		},
		{
			name: "Equipment",
			content: <EquipmentForm vendorIdOptions={vendorIdOptions}></EquipmentForm>,
		},
		{
			name: "Generator",
			content: <GeneratorForm vendorIdOptions={vendorIdOptions}></GeneratorForm>,
		},

		{
			name: "Ground Transportation",
			content: <GroundTransportationForm vendorIdOptions={vendorIdOptions}></GroundTransportationForm>,
		},
		{
			name: "Salary",
			content: <SalaryForm vendorIdOptions={vendorIdOptions}></SalaryForm>,
		},
		{
			name: "Food Expense",
			content: <FoodForm vendorIdOptions={vendorIdOptions}></FoodForm>,
		},
		{
			name: "Cash Advance",
			content: <CashAdvanceForm vendorIdOptions={vendorIdOptions}></CashAdvanceForm>,
		},
	];

	return (
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						{/* <div className="col-xl-3 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-primary">
								<div className="card-body  p-4">
									<div className="media">
										<span className="me-3">
											<i className="la la-users"></i>
										</span>
										<div className="media-body text-white">
											<p className="mb-1">Total Students</p>
											<h3 className="text-white">3280</h3>
											<div className="progress mb-2 bg-secondary">
												<div
													className="progress-bar progress-animated bg-light"
													style={{ width: "80%" }}
												></div>
											</div>
											<small>80% Increase in 20 Days</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-warning">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="la la-user"></i>
										</span>
										<div className="media-body text-white">
											<p className="mb-1">New Students</p>
											<h3 className="text-white">245</h3>
											<div className="progress mb-2 bg-primary">
												<div
													className="progress-bar progress-animated bg-light"
													style={{ width: "50%" }}
												></div>
											</div>
											<small>50% Increase in 25 Days</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-secondary">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="la la-graduation-cap"></i>
										</span>
										<div className="media-body text-white">
											<p className="mb-1">Total Course</p>
											<h3 className="text-white">28</h3>
											<div className="progress mb-2 bg-primary">
												<div
													className="progress-bar progress-animated bg-light"
													style={{ width: "76%" }}
												></div>
											</div>
											<small>76% Increase in 20 Days</small>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-3 col-lg-6 col-sm-6">
							<div className="widget-stat card bg-danger ">
								<div className="card-body p-4">
									<div className="media">
										<span className="me-3">
											<i className="la la-dollar"></i>
										</span>
										<div className="media-body text-white">
											<p className="mb-1">Fees Collect</p>
											<h3 className="text-white">250$</h3>
											<div className="progress mb-2 bg-secondary">
												<div
													className="progress-bar progress-animated bg-light"
													style={{ width: "30%" }}
												></div>
											</div>
											<small>30% Increase in 30 Days</small>
										</div>
									</div>
								</div>
							</div>
						</div> */}
						<div className="col-xl-12">
							<div className="row">
								<Card>
									<Card.Header className='border-0 pb-0' >
										<Card.Title>Add New Transaction</Card.Title>
									</Card.Header>
									<Card.Body>
										{/* <!-- Nav tabs --> */}
										<div className="default-tab">
											<Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
												<Nav as="ul" className="nav-tabs">
													{tabData.map((data, i) => (
														<Nav.Item as="li" key={i}>
															<Nav.Link eventKey={data.name.toLowerCase()}>
																{data.name}
															</Nav.Link>
														</Nav.Item>
													))}
												</Nav>
												<Tab.Content className="pt-4">
													{tabData.map((data, i) => (
														<Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
															<p>{data.content}</p>
														</Tab.Pane>
													))}
												</Tab.Content>
											</Tab.Container>
										</div>
									</Card.Body>
								</Card>
							</div>
						</div>
						<div className="col-xl-6">
							<div className="row">
								<div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
									<div className="card">
										<div className="card-header border-0 pb-0">
											<h4 className="card-title">Recent Transactions</h4>
										</div>
										<div className="card-body">
											<div className="table-responsive recentOrderTable">
												<table className="table verticle-middle table-responsive-md">
													<thead>
														<tr>
															<th scope="col">Bill Id</th>
															<th scope="col">Bill Type</th>
															<th scope="col">Bill Amount</th>
															<th scope="col">Bill Status</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>12</td>
															<td>Dr. Jackson</td>
															<td>01 August 2020</td>
															<td>
																<span className="badge badge-rounded badge-primary">
																	Checkin
																</span>
															</td>
														</tr>
														<tr>
															<td>10 </td>
															<td>Mr. Dexter</td>
															<td>31 July 2020</td>
															<td>
																<span className="badge badge-rounded badge-warning">
																	Panding
																</span>
															</td>

														</tr>
														<tr>
															<td>03 </td>
															<td>Mr. Nathan</td>
															<td>30 July 2020</td>
															<td>
																<span className="badge badge-rounded badge-danger">
																	Canceled
																</span>
															</td>

														</tr>
														<tr>
															<td>05</td>
															<td>Mr. Aurora</td>
															<td>29 July 2020</td>
															<td>
																<span className="badge badge-rounded badge-success">
																	Checkin
																</span>
															</td>
														</tr>
														<tr>
															<td>06</td>
															<td>Mr. Matthew</td>
															<td>28 July 2020</td>
															<td>
																<span className="badge badge-rounded badge-success">
																	Checkin
																</span>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6">
							<div className="row">
								<div className="col-lg-12">
									<div className="card">
										<div className="card-header border-0 pb-0">
											<h4 className="card-title">Timeline</h4>
										</div>
										<div className="card-body">
											<PerfectScrollbar
												style={{ height: "370px" }}
												id="DZ_W_TimeLine1"
												className="widget-timeline dlab-scroll style-1 height370 ps ps--active-y"
											>
												<ul className="timeline">
													<li>
														<div className="timeline-badge primary"></div>
														<Link
															className="timeline-panel text-muted"
															to="/widget-basic"
														>
															<span>10 minutes ago</span>
															<h6 className="mb-0">
																Youtube, a video-sharing website, goes live{" "}
																<strong className="text-primary">$500</strong>.
															</h6>
														</Link>
													</li>
													<li>
														<div className="timeline-badge info"></div>
														<Link
															className="timeline-panel text-muted"
															to="/widget-basic"
														>
															<span>20 minutes ago</span>
															<h6 className="mb-0">
																New order placed{" "}
																<strong className="text-info">#XF-2356.</strong>
															</h6>
															<p className="mb-0">
																Quisque a consequat ante Sit amet magna at volutapt
															</p>
														</Link>
													</li>
													<li>
														<div className="timeline-badge danger"></div>
														<Link
															className="timeline-panel text-muted"
															to="/widget-basic"
														>
															<span>30 minutes ago</span>
															<h6 className="mb-0">
																john just buy your product{" "}
																<strong className="text-warning">Sell $250</strong>
															</h6>
														</Link>
													</li>
													<li>
														<div className="timeline-badge success"></div>
														<Link
															className="timeline-panel text-muted"
															to="/widget-basic"
														>
															<span>15 minutes ago</span>
															<h6 className="mb-0">
																StumbleUpon is acquired by eBay.{" "}
															</h6>
														</Link>
													</li>
													<li>
														<div className="timeline-badge warning"></div>
														<Link
															className="timeline-panel text-muted"
															to="/widget-basic"
														>
															<span>20 minutes ago</span>
															<h6 className="mb-0">
																Mashable, a news website and blog, goes live.
															</h6>
														</Link>
													</li>
													<li>
														<div className="timeline-badge dark"></div>
														<Link
															className="timeline-panel text-muted"
															to="/widget-basic"
														>
															<span>20 minutes ago</span>
															<h6 className="mb-0">
																Mashable, a news website and blog, goes live.
															</h6>
														</Link>
													</li>
												</ul>
											</PerfectScrollbar>
										</div>
									</div>
								</div>



							</div>
						</div>
						
						{/* <div className='col-xl-12'>
							<div className='card filter__card'>
								<div className='card-header pb-0 border-0'><h4>Filters</h4></div>
								<div className='card-body'>
									<div>
										<button className='btn btn-outline-primary mx-1 my-1'>All Category</button>
										<button className='btn btn-outline-primary mx-1 my-1'>Propery and Materials</button>
										<button className='btn btn-outline-primary mx-1 my-1'>Equipment</button>
										<button className='btn btn-outline-primary mx-1 my-1'>Generator</button>
										<button className='btn btn-outline-primary mx-1 my-1'>Transportation</button>
										<button className='btn btn-outline-primary mx-1 my-1'>Salaries</button>
										<button className='btn btn-outline-primary mx-1 my-1'>Transportation</button>
										<button className='btn btn-outline-primary mx-1 my-1'>Salaries</button>
									</div>
									<div className='my-2'>
										<RangeDatePicker
											startText="Start"
											endText="End"
											startPlaceholder="Start Date"
											endPlaceholder="End Date"
											style={{ "border-radius": "15px" }}
										/>
									</div>

								</div>
							</div>

						</div> */}
						{/* <div className='row'>
							<div className="col-xl-6">
								<div className="card">
									<div className="card-header border-0 pb-0">
										<h4 className="fs-20 font-w700 mb-0">Completion Project Rate</h4>
										<Dropdown className="dropdown ms-2">
											<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5" />
													<circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5" />
													<circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5" />
												</svg>
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
												<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
												<Dropdown.Item className="dropdown-item">Edit</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
									<div className="card-body pb-0">
										<div id="revenueMap" className="revenueMap">
											<CompletionApexChart />
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-6">
								<div className="card">
									<div className="card-header border-0">
										<div>
											<h4 className="fs-20 font-w700">Email Categories</h4>
											<span className="fs-14 font-w400 d-block">Lorem ipsum dolor sit amet</span>
										</div>
									</div>
									<div className="card-body row">
										<div id="emailchart" className='col-lg-6'>
											<EmailChartApex />
										</div>
										<div className='col-lg-6'>
											<div className="mb-3 mt-4">
												<h4 className="fs-18 font-w600">Legend</h4>
											</div>
											<div>
												<div className="d-flex align-items-center justify-content-between mb-4">
													<span className="fs-18 font-w500">
														<svg className="me-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect width="20" height="20" rx="6" fill="#886CC0" />
														</svg>
														Primary (27%)
													</span>
													<span className="fs-18 font-w600">763</span>
												</div>
												<div className="d-flex align-items-center justify-content-between  mb-4">
													<span className="fs-18 font-w500">
														<svg className="me-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect width="20" height="20" rx="6" fill="#26E023" />
														</svg>
														Promotion (11%)
													</span>
													<span className="fs-18 font-w600">321</span>
												</div>
												<div className="d-flex align-items-center justify-content-between  mb-4">
													<span className="fs-18 font-w500">
														<svg className="me-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect width="20" height="20" rx="6" fill="#61CFF1" />
														</svg>
														Forum (22%)
													</span>
													<span className="fs-18 font-w600">69</span>
												</div>
												<div className="d-flex align-items-center justify-content-between  mb-4">
													<span className="fs-18 font-w500">
														<svg className="me-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect width="20" height="20" rx="6" fill="#FFDA7C" />
														</svg>
														Socials (15%)
													</span>
													<span className="fs-18 font-w600">154</span>
												</div>
												<div className="d-flex align-items-center justify-content-between  mb-4">
													<span className="fs-18 font-w500">
														<svg className="me-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect width="20" height="20" rx="6" fill="#FF86B1" />
														</svg>
														Spam (25%)
													</span>
													<span className="fs-18 font-w600">696</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<Col xl={6} lg={6}>
								<Card>
									<Card.Header className=' pb-0 border-0'>
										<h4 className="card-title">Bar Chart</h4>
									</Card.Header>
									<Card.Body>
										<ApexBar3 />
									</Card.Body>
								</Card>
							</Col>
							<Col xl={6} lg={6}>
								<Card>
									<Card.Header className=' pb-0 border-0'>
										<h4 className="card-title">Line</h4>
									</Card.Header>
									<Card.Body>
										<ApexLine />
									</Card.Body>
								</Card>
							</Col>
						</div> */}
					</div>
				</div>
			</div>
		</>
	)
}
export default Home;