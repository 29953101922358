import React, { Fragment, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { pushNotify } from "../../../../services/NotifyService"
import { Axios, AxiosGet } from "../../../../context/UserContext"
import { ScaleLoader } from "react-spinners";

function StatsTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleData, setScheduleData] = useState(null);

    const fetchScheduleData = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosGet('get_schedules.php');
            console.log(data);
            if (data.success) setScheduleData(data);
            else pushNotify("error", "Error", data.error)
        }
        catch (err) {
            pushNotify("error", "Error", "Server Error!")
        }
        finally{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchScheduleData()
    }, [])

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                    <div className="widget-stat card">
                        <div className="card-body p-4">
                            <div className="media ai-icon">
                                <span className="me-3 bgl-primary text-primary">
                                    <i class="fas fa-hashtag"></i>
                                </span>
                                <div className="media-body">
                                    <p className="mb-1">Total Schedules<button className='btn btn-refresh' onClick={fetchScheduleData}><i class="fas fa-sync-alt"></i></button></p>
                                    <h4 className="mb-0">{(scheduleData==null)?("Loading..."):(scheduleData.total_schedules)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                    <div className="widget-stat card">
                        <div className="card-body p-4">
                            <div className="media ai-icon">
                                <span className="me-3 bgl-warning text-warning">
                                    <i class="fas fa-check"></i>
                                </span>
                                <div className="media-body">
                                    <p className="mb-1">Active Schedules</p>
                                    <h4 className="mb-0">{(scheduleData==null)?("Loading..."):(scheduleData.unfinished_schedules)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                    <div className="widget-stat card">
                        <div className="card-body  p-4">
                            <div className="media ai-icon">
                                <span className="me-3 bgl-danger text-danger">
                                    <i class="fas fa-check-double"></i>
                                </span>
                                <div className="media-body">
                                    <p className="mb-1">Finished Schedules</p>
                                    <h4 className="mb-0">{(scheduleData==null)?("Loading..."):(scheduleData.finished_schedules)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
                    <div className="widget-stat card">
                        <div className="card-body p-4">
                            <div className="media ai-icon">
                                <span className="me-3 bgl-success text-success">
                                    <i class="fas fa-calendar-alt"></i>
                                </span>
                                <div className="media-body">
                                    <p className="mb-1">Upcoming Schedule</p>
                                    <h4 className="mb-0">{(scheduleData==null)?("Loading..."):((scheduleData.upcoming==false)?"none":(scheduleData.upcoming.title))}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                <div className="card">
                    <div className="card-header border-0 pb-0">
                        <h4 className="card-title">Schedules<button className='btn btn-refresh' onClick={fetchScheduleData}><i class="fas fa-sync-alt"></i></button></h4>
                    </div>
                    <div className="card-body" style={{ "height": "600px" }}>
                        <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                            {
                                (scheduleData == null||isLoading)?
                                    <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
                                    : (
                                        <table className="table verticle-middle table-responsive-md">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Shoot Date</th>
                                                    <th scope="col">City</th>
                                                    <th scope="col">Call Sheet</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {scheduleData.schedules.map(schedule => (
                                                    <tr key={schedule.sl}>
                                                        <td>{schedule.title}</td>
                                                        <td>{schedule.shoot_date}</td>
                                                        <td>{schedule.city}</td>
                                                        <td>{schedule.call_sheet}</td>
                                                        <td>
                                                            <Dropdown className="dropdown custom-dropdown mb-0">
                                                                <Dropdown.Toggle className="btn sharp btn-primary tp-btn i-false" data-toggle="dropdown">
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                    <Dropdown.Item className="dropdown-item">
                                                                        Details
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item text-danger">
                                                                        Cancel
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>

                                    )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default StatsTable;