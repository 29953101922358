import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { AxiosPost, convertNumberToWords } from "../../../context/UserContext";
import { pushNotify } from "../../../services/NotifyService";
import { useEffect } from "react";
function MovieDashboard() {
    const initialFormData = { category: '', role: '', amount: 0 }
    const [isLoading, setIsLoading] = useState(false);
    const [budgets, setBudgets] = useState(null);
    const [amount,setAmount]=useState(null);
    const [editBudget,setEditBudget]=useState({show:false,estimated_amount:'0',role_id:null,category_id:null})
    let current = "";
    let found = false;
    const fetchBudgets = async () => {
        try {
            setIsLoading(true);
            const data = await AxiosPost("get_budgets_data.php");
            console.log(data);
            if (data.success) {
                setBudgets(data);
            } else {
                pushNotify('error', 'Error', data.error);
            }

        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
        }
        finally {
            setIsLoading(false);
        }
    }

    const filterUtil = (element, role) => {
        if (element.role_value == role.role_value) {
            found = true;
            return true;
        }
        else return false;
    }

    const handleEditBudget = async(role_id,category_id)=>{
        try {
            setIsLoading(true);
            const data = await AxiosPost("edit_budget.php",editBudget);
            console.log(amount);
            if (data.success) {
                setEditBudget({...editBudget,show:false,estimated_amount:'0',role_id:null,category_id:null})
                pushNotify('success',"Success","Budget edited successfully")
                fetchBudgets()
            } else {
                pushNotify('error', 'Error', data.error);
            }

        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
        }
        finally {
            setIsLoading(false);
        }
    }

    const cancelEditBudget = ()=>{
        setEditBudget({...editBudget,show:false,estimated_amount:'0',role_id:null,category_id:null})
    }

    useEffect(() => {
        fetchBudgets();
    }, [])

    return (
        <>
            <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Movie Budgeting</h4>
                    </div>
                    <div className="card-body" >
                        <div className="table-responsive recentOrderTable" style={{ height: "100%" }}>
                            {(budgets == null || isLoading) ? <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :
                                <table className="table" style={{ width: "fit-content" }}>
                                    <tbody>
                                        {(Object.values(budgets.roles).filter(element=>element.category_name=="Master_User"?false:true).map((role, i) => current == role.category_name ?
                                            <tr key={i}>
                                                <td>{role.role_value}</td>
                                                {found = false}
                                                {Object.values(budgets.budgets).filter(element => filterUtil(element, role)).map(
                                                    element => <td>{Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(element.estimated_budget)}</td>
                                                )}
                                                {found ? null : <td>{Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format('0')}</td>}
                                                {/* <td>
                                                    <input type="number" min={0} onChange={(e)=>setAmount(e.currentTarget.value)} className="form-control" />
                                                </td> */}
                                                <td>
                                                    <button class="btn btn-primary tp-btn sharp" onClick={ ()=>setEditBudget({...editBudget,show:true,role_id:role.role_id,category_id:role.category_id})}><i className="fas fa-edit"></i></button>
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                <h3 style={{ marginTop: "10px" }}>{current = role.category_name} - {Object.values(budgets.totals).filter(element=>element.category_name==current?true:false).map(element=> <span>{Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(element.category_total)}</span>)}</h3>
                                                <tr key={i}>
                                                    <td>{role.role_value}</td>
                                                    {Object.values(budgets.budgets).filter(element => filterUtil(element, role)).map(
                                                    element => <td>{Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(element.estimated_budget)}</td>
                                                    )}
                                                    {found ? null : <td>{Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format('0')}</td>}
                                                    {/* <td>
                                                    <input type="number" min={0} onChange={(e)=>setAmount(e.currentTarget.value)} className="form-control" />
                                                </td> */}
                                                <td>
                                                    <button class="btn btn-primary tp-btn sharp" onClick={ ()=>setEditBudget({...editBudget,show:true,role_id:role.role_id,category_id:role.category_id})}><i className="fas fa-edit"></i></button>
                                                </td>
                                                </tr>
                                            </>

                                        ))}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="fade" show={editBudget.show} centered>
                <Modal.Header>
                    <Modal.Title>Edit Budget</Modal.Title>
                    <Button onClick={cancelEditBudget} variant="" className="btn-close"></Button>
                </Modal.Header>
                <Modal.Body>
                    <label>Estimated Amount</label>
                    <input type="number" min={0} className="form-control" placeholder="Enter amount" name="settled_amount" value={editBudget.estimated_amount} onChange={(e) => setEditBudget({ ...editBudget, "estimated_amount": e.target.value })} />
                    <div style={{ color: "green" }}>{convertNumberToWords(editBudget.estimated_amount)}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleEditBudget} variant="success light">Confirm</Button>
                    <Button variant="primary" onClick={cancelEditBudget}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}
export default MovieDashboard;