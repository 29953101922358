import React from 'react'
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners';
import { AxiosPost } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import TransactionInfo from './TransactionInfo';

function TransactionPage(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [billInfo, setBillInfo] = useState(null);
    const [vendorInfo, setVendorInfo] = useState(null);
    const [settleBill, setSettleBill] = useState({ show: false, bill_id: null, settled_amount: null });

    useEffect(() => {
        window.scrollTo(0, 0)
        showBillDetails();
        getVendorInfo();
    }, [])

    const getVendorInfo = async () => {
        setIsLoading2(true);
        try {
            const data = await AxiosPost('get_account_info.php', { "vendor_id": props.vendorId })
            if (data.success) {
                setVendorInfo(data);
            }
            else pushNotify('error', "Error", data.error)
        }
        catch (err) {
            pushNotify('error', "Error", "Server Error!")
        }
        finally {
            setIsLoading2(false)
        }
    }

    const showBillDetails = async () => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('get_trans_info.php', { "bill_id": props.selectedBillId })
            if (data.success) {
                setBillInfo(data);
                console.log(data);
            }
            else pushNotify('error', "Error", data.error)
        }
        catch (err) {
            pushNotify('error', "Error", "Server Error!")
        }
        finally {
            setIsLoading(false)
        }
    }

    const approveTrans = async (id) => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('approve_trans.php', { "bill_id": id, "bill_amount": billInfo.transaction_info.bill_amount });
            if (data.success) {
                pushNotify('success', "Success", "Bill Approved Successfully")
                showBillDetails();
                getVendorInfo();
            }
            else pushNotify('error', "Error", data.error)
        }
        catch (err) {
            pushNotify('error', "Error", "Server Error!")
        }
        finally {
            setIsLoading(false)
        }

    }

    const holdTrans = async (id) => {
        setIsLoading(true);
        try {
            const data = await AxiosPost('hold_trans.php', { "bill_id": id });
            if (data.success) {
                pushNotify('success', "Success", "Bill Held Successfully")
                showBillDetails();
                getVendorInfo();
            }
            else pushNotify('error', "Error", data.error)
        }
        catch (err) {
            pushNotify('error', "Error", "Server Error!")
        }
        finally {
            setIsLoading(false);
        }
    }

    const settleTrans = (id) => {
        setSettleBill({ show: true, bill_id: id });
    }

    const cancelSettleTrans = () => {
        setSettleBill({ show: false, bill_id: null, bill_amount: null });
    }

    const handleSettleTrans = async () => {
        setIsLoading(true);
        if (settleBill.settled_amount == null) {
            pushNotify("error", "Error", "Please enter valid settled amount.")
            setIsLoading(false);
            return;
        }
        try {
            const data = await AxiosPost('settle_trans.php', { "bill_id": settleBill.bill_id, "settled_amount": settleBill.settled_amount });
            if (data.success) {
                setSettleBill({ show: false, bill_id: null, bill_amount: null })
                console.log(data)
                pushNotify("success", "Success", "Bill Settled successfully")
                showBillDetails();
                getVendorInfo();
            }
            else {
                pushNotify("error", "Error", data.error)
            }

        }
        catch (err) {
            pushNotify("error", "Error", "Server Error!")
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <div class="row">
                <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title"><button class="btn btn-primary tp-btn sharp" onClick={() => { props.fetchTransactionsData(); props.setTransComponent(false); props.setSelectedBillId(null) }}><i class="fas fa-arrow-left"></i></button> Transaction Info</h4>
                        </div>
                        <div className="card-body" style={{ "min-height": "600px" }}>
                            {(billInfo == null || isLoading) ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> : (
                                <TransactionInfo billInfo={billInfo}></TransactionInfo>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">File Uploads</h4>
                        </div>
                        <div className="card-body row">
                            {(billInfo == null || isLoading) ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                            (billInfo.uploads==null)?<div>No file uploads for the selected bill.</div>: (
                                billInfo.uploads.map(value => (<div style={{"width":"fit-content","margin":"10px"}}><a target="_blank" className='btn btn-dark' href={"https://api.flickanalytics.in/FlickAPIs/uploads/" + value}>{value}</a></div>))

                            )}
                        </div>
                    </div>
                </div>
                {
                    billInfo == null || vendorInfo == null ? null : (
                        <>
                            {billInfo.transaction_info.bill_status == "Raised" || billInfo.transaction_info.bill_status == "Held" ? (
                                <div className="col-xl-4 col-lg-6 col-xxl-6 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Approve</h4>
                                        </div>
                                        <div className="card-body" style={{ "min-height": "300px" }}>
                                            <div className="table-responsive recentOrderTable">
                                                <table className="table verticle-middle">
                                                    <tr><td><b>Estimated Budget </b></td><td>: Rs. {vendorInfo.info['0'].estimated_budget}</td></tr>
                                                    <tr><td><b>Final Budget </b></td><td>: Rs. {vendorInfo.info['0'].final_budget}</td></tr>
                                                    <tr><td><b>Total Amount Paid </b></td><td>: Rs. {vendorInfo.info['0'].actual_amount_paid}</td></tr>
                                                    <tr><td><b>Bill Amount </b></td><td>: Rs. {billInfo.transaction_info.bill_amount}</td></tr>
                                                </table>

                                                <div>
                                                    {
                                                        parseInt(vendorInfo.info['0'].final_budget) - parseInt(billInfo.transaction_info.bill_amount) > 0 ?
                                                            (<div style={{ "color": "green" }}><i class="fas fa-check"></i> Bill Amount is less than the balance amount.</div>)
                                                            :
                                                            (<div style={{ "color": "red" }}><i class="far fa-times"></i> Bill Amount exceeds the balance amount from final budget. Approval not recommended.</div>)
                                                    }
                                                </div>
                                                <div>
                                                    <button className="btn btn-success " onClick={() => approveTrans(billInfo.transaction_info.bill_id)}>Approve</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {(billInfo.transaction_info.bill_status == "Raised" || billInfo.transaction_info.bill_status == "Approved") ? (
                                <div className="col-xl-4 col-lg-6 col-xxl-6 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Hold</h4>
                                        </div>
                                        <div className="card-body" style={{ "min-height": "300px" }}>
                                            <div className="table-responsive recentOrderTable">
                                                <table className="table verticle-middle">
                                                    <tr><td><b>Estimated Budget </b></td><td>: Rs. {vendorInfo.info['0'].estimated_budget}</td></tr>
                                                    <tr><td><b>Final Budget </b></td><td>: Rs. {vendorInfo.info['0'].final_budget}</td></tr>
                                                    <tr><td><b>Total Amount Paid </b></td><td>: Rs. {vendorInfo.info['0'].actual_amount_paid}</td></tr>
                                                    <tr><td><b>Bill Amount </b></td><td>: Rs. {billInfo.transaction_info.bill_amount}</td></tr>
                                                </table>

                                                <div>
                                                    {
                                                        parseInt(vendorInfo.info['0'].final_budget) - parseInt(billInfo.transaction_info.bill_amount) > 0 ?
                                                            (<div style={{ "color": "green" }}><i class="fas fa-check"></i> Bill Amount is less than the balance amount.</div>)
                                                            :
                                                            (<div style={{ "color": "red" }}><i class="far fa-times"></i> Bill Amount exceeds the balance amount from final budget. Bill can be held for further verification.</div>)
                                                    }
                                                </div>
                                                <div>
                                                    <button className="btn btn-danger " onClick={() => holdTrans(billInfo.transaction_info.bill_id)}>Hold</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {billInfo.transaction_info.bill_status == "Approved" ? (
                                <div className="col-xl-4 col-lg-6 col-xxl-6 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Settle</h4>
                                        </div>
                                        <div className="card-body" style={{ "min-height": "300px" }}>
                                            <div className="table-responsive recentOrderTable">
                                                <table className="table verticle-middle">
                                                    <tr><td><b>Estimated Budget </b></td><td>: Rs. {vendorInfo.info['0'].estimated_budget}</td></tr>
                                                    <tr><td><b>Final Budget </b></td><td>: Rs. {vendorInfo.info['0'].final_budget}</td></tr>
                                                    <tr><td><b>Total Amount Paid </b></td><td>: Rs. {vendorInfo.info['0'].actual_amount_paid}</td></tr>
                                                    <tr><td><b>Bill Amount </b></td><td>: Rs. {billInfo.transaction_info.bill_amount}</td></tr>
                                                </table>

                                                <div>
                                                    {
                                                        parseInt(vendorInfo.info['0'].final_budget) - parseInt(billInfo.transaction_info.bill_amount) > 0 ?
                                                            (<div style={{ "color": "green" }}><i class="fas fa-check"></i> Bill Amount is less than the balance amount.</div>)
                                                            :
                                                            (<div style={{ "color": "red" }}><i class="far fa-times"></i> Bill Amount exceeds the balance amount from final budget. Settle not recommended.</div>)
                                                    }
                                                </div>
                                                <div>
                                                    <button className="btn btn-success " onClick={() => settleTrans(billInfo.transaction_info.bill_id)}>Settle</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    )
                }
                <div className='row'>
                    <image width="100%" height="400" data="http://www.africau.edu/images/default/sample.pdf" >   </image>

                </div>
            </div>
            <Modal className="fade" show={settleBill.show} centered>
                <Modal.Header>
                    <Modal.Title>Settle Transaction</Modal.Title>
                    <Button onClick={cancelSettleTrans} variant="" className="btn-close"></Button>
                </Modal.Header>
                <Modal.Body>
                    <label>Settled Amount</label>
                    <input type="number" className="form-control" placeholder="Enter amount" name="settled_amount" value={settleBill.settled_amount} onChange={(e) => setSettleBill({ ...settleBill, "settled_amount": e.target.value })} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSettleTrans} variant="success light">Confirm</Button>
                    <Button variant="primary" onClick={cancelSettleTrans}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TransactionPage