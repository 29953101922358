import React, { useState } from 'react';
import Select from "react-select";
import { Axios, AxiosPost, convertNumberToWords } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import {ScaleLoader} from 'react-spinners'
import axios from 'axios';
import FileUpload from '../FileUpload/FileUpload';
import FileList from '../FileUpload/FileList/FileList'

function GroundTransportationForm(props) {
    const initialFormData = { vehicle_number: '', vendor_id: null, used_for: null, remarks: '', total_amount: '', fuel_charges: '', driver_bata: '', other_charges: '', shoot_date:'', transaction_mode: null }
    const [formData, setFormData] = useState(initialFormData);
    const transactionModeOptions = [{ value: "Cash", label: "Cash" }, { value: "Digital", label: "Digital" }, {value:"cash_advance",label:"Cash Advance"}]
    const [isLoading,setIsLoading]=useState(false);
    const [files, setFiles] = useState([]);

    const removeFile = (filename) => {
        setFiles(files.filter(file => file.name !== filename))
    }

    const onChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const formSubmitHandler = async(e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            if (!Object.values(formData).every(val => {
                if(typeof(val)!='object'){
                    if(val.trim()=='')return false;
                    else return true;
                }
                else{ 
                    if(val!=null)return true;
                    else return false;
                }
            })) {
                pushNotify('error', 'Error', 'Please Fill in all Required Fields! in');
                return;
            }
            const data = await AxiosPost('add_ground_trans.php', formData);
            if (data.success) {
                const form_data = new FormData();
                form_data.append("bill_id",data.bill_id)
                for (let i = 0; i < files.length; i++) {
                  form_data.append("files[]", files[i]);
                }
                console.log(form_data);
                const filuploadres=await axios.post('https://api.flickanalytics.in/FlickAPIs/file_upload.php', form_data, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
                console.log(filuploadres)
                setFiles([])
                pushNotify('success', "Success", 'Bill Raised Successfully');
                setFormData(initialFormData);
            }
            else pushNotify('error', "Error", data.error);

        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
        } finally{
            setIsLoading(false)
        }
    }

    return (
        <>
            {
                isLoading ? <div className="row mx-0" style={{ height:"500px" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
                    :
        <div className="basic-form">
            <form onSubmit={formSubmitHandler}>
                <div className="row">
                    <div className="form-group mb-3 col-md-6">
                        <label>Vehicle Number <span style={{"color":"red"}}>*</span></label>
                        <input type="text" className='form-control' name='vehicle_number' value={formData.vehicle_number} onChange={onChangeHandler} />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Vendor Username <span style={{"color":"red"}}>*</span></label>
                        <Select
                            value={formData.vendor_id}
                            onChange={(e) => { setFormData({ ...formData, "vendor_id": e }) }}
                            options={props.vendorIdOptions}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Used For</label>
                        <Select
                            value={formData.used_for}
                            onChange={(e) => { setFormData({ ...formData, "used_for": e }) }}
                            options={props.vendorIdOptions}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Shoot Date <span style={{"color":"red"}}>*</span></label>
                        <input type="date" className="form-control" name='shoot_date' value={formData.shoot_date} onChange={onChangeHandler} />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Fuel Charges</label>
                        <input type="number" min={0} className="form-control" name='fuel_charges' value={formData.fuel_charges} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.fuel_charges)}</div>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Driver Bata</label>
                        <input type="number" min={0} className="form-control" name='driver_bata' value={formData.driver_bata} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.driver_bata)}</div>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Other Charges</label>
                        <input type="number" min={0} className="form-control" name='other_charges' value={formData.other_charges} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.other_charges)}</div>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Total Amount <span style={{"color":"red"}}>*</span></label>
                        <input type="number" min={0} className="form-control" name='total_amount' value={formData.total_amount} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.total_amount)}</div>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Transaction Mode <span style={{"color":"red"}}>*</span></label>
                        <Select
                            value={formData.transaction_mode}
                            onChange={(e) => { setFormData({ ...formData, "transaction_mode": e }) }}
                            options={transactionModeOptions}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Remarks</label>
                        <input type="text" className="form-control" name='remarks' value={formData.remarks} onChange={onChangeHandler} />
                    </div>
                                <div className='col-md-12'>
                                    <FileList files={files} removeFile={removeFile} />
                                </div>
                </div>
                                    <FileUpload files={files} setFiles={setFiles}
                                        removeFile={removeFile} />
                <button type="submit" className="btn btn-primary">
                    Add Expense
                </button>
            </form>
        </div>
}
</>
    );
}
export default GroundTransportationForm