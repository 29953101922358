import React from "react";

function Stats(props){
    return(
        <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-success text-success">
                  <i class="fas fa-hashtag"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Settled Bills</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.settled_trans[0].count}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-success text-success">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Settled Amount</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.settled_trans[0].total==null?'0':props.transactionsData.settled_trans[0].total}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body  p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-primary text-primary">
                  <i class="fas fa-hashtag"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Raised Bills</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.raised_trans[0].count}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-primary text-primary">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Raised Amount</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.raised_trans[0].total==null?'0':props.transactionsData.raised_trans[0].total}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-warning text-warning">
                  <i class="fas fa-hashtag"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Approved Bills</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.approved_trans[0].count}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-warning text-warning">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Approved Amount</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.approved_trans[0].total==null?'0':props.transactionsData.approved_trans[0].total}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body  p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-danger text-danger">
                  <i class="fas fa-hashtag"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Held Bills</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.held_trans[0].count}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="media ai-icon">
                <span className="me-3 bgl-danger text-danger">
                  <i class="fas fa-dollar-sign"></i>
                </span>
                <div className="media-body">
                  <p className="mb-1">Held Amount</p>
                  <h4 className="mb-0">{(props.transactionsData==null)?"Loading...":props.transactionsData.held_trans[0].total==null?'0':props.transactionsData.held_trans[0].total}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
}

export default Stats;