import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { ScaleLoader } from "react-spinners";
import { Axios, AxiosPost } from "../../../../context/UserContext";
import { pushNotify } from "../../../../services/NotifyService";

const options = [
	{ value: "equipment", label: "equipment" },
	{ value: "property", label: "property" },
];

function Stats(props) {
	const initialFormData = { item_name: '', item_type: '' }
	const [formData, setFormData] = useState(initialFormData);
	const [modalCentered, setModalCentered] = useState(false);
	const [isLoading,setIsLoading]=useState(false);
	const onChangeInput = (e) => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		})
	}

	const onChangeSelect = (e) => {
		setFormData({
			...formData,
			"item_type": e.value
		})
	}

	const addItemSubmitHandler = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		if (!Object.values(formData).every(val => val.trim() !== '')) {
			pushNotify('error', 'Error', 'Please Fill in all Required Fields! in');
			setIsLoading(false);
			return;
		}
		try {
			const data = await AxiosPost('add_item.php', formData);
			if (data.success) {
				pushNotify('success', 'Success', 'Item Added Successfully.');
				setFormData({ ...initialFormData });
				setModalCentered(false);
				props.fetchItemMasterData();
			} else {
				pushNotify('error', 'Error', data.error);
			}

		} catch (err) {
			pushNotify("error", "Error", "Server Error!");
		}
		finally{
			setIsLoading(false);
		}
	}

	return (
		<div className="row">
			<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
				<div className="widget-stat card">
					<div className="card-body p-4">
						<div className="media ai-icon">
							<span className="me-3 bgl-primary text-primary">
								<i class="fas fa-hashtag"></i>
							</span>
							<div className="media-body">
								<p className="mb-1" >Total Items</p>
								<h4 className="mb-0">{(props.itemMasterData == null) ? ("Loading...") : (props.itemMasterData.totalItems)}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
				<div className="widget-stat card">
					<div className="card-body p-4">
						<div className="media ai-icon">
							<span className="me-3 bgl-warning text-warning">
								<i class="fas fa-toolbox"></i>
							</span>
							<div className="media-body">
								<p className="mb-1">Equipment Items</p>
								<h4 className="mb-0">{(props.itemMasterData == null) ? ("Loading...") : (props.itemMasterData.equipmentItems)}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
				<div className="widget-stat card">
					<div className="card-body  p-4">
						<div className="media ai-icon">
							<span className="me-3 bgl-danger text-danger">
								<i class="fas fa-home"></i>
							</span>
							<div className="media-body">
								<p className="mb-1">Property Items</p>
								<h4 className="mb-0">{(props.itemMasterData == null) ? ("Loading...") : (props.itemMasterData.propertyItems)}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
				<button className="widget-stat card bg-success" style={{ "width": "100%" }} onClick={() => setModalCentered(true)}>
					<div className="card-body p-4">
						<div className="media">
							<span className="me-3">
								<i class="fas fa-plus"></i>
							</span>
							<div className="media-body text-white text-end">
								<h4 className="text-white">Add Item</h4>
							</div>
						</div>
					</div>
				</button>
				{/* <!-- Modal --> */}
				<Modal className="fade" show={modalCentered} centered>
					<Modal.Header>
						<Modal.Title>Add Item</Modal.Title>
						<Button
							onClick={() => setModalCentered(false)}
							variant=""
							className="btn-close"
						>

						</Button>
					</Modal.Header>
					<form onSubmit={(e) => { addItemSubmitHandler(e); }}>
						{isLoading?<ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
						(
							<Modal.Body>

							<label htmlFor="Item Name">Item Name</label>
							<input type="text" className="form-control" name="item_name" id="item_name" value={formData.item_name} onChange={onChangeInput} />
							<label htmlFor="Item Type">Item Type</label>
							<Select
								defaultValue={formData.item_type}
								onChange={onChangeSelect}
								options={options}
							/>

						</Modal.Body>
						)}
						<Modal.Footer>
							<Button onClick={() => setModalCentered(false)} variant="danger light">Close</Button>
							<Button variant="primary" type="submit">Add Item</Button>
						</Modal.Footer>
					</form>
				</Modal>

			</div>

		</div>
	);
}
export default Stats;