import React from "react";
import { Modal, Button } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";

function InfoModal(props){
    return(
        <Modal className="fade" show={props.showInfoModal} size="xl" centered>
                <Modal.Header>
                    <Modal.Title>Account Info</Modal.Title>
                    <Button
                        onClick={() => props.setShowInfoModal(false)}
                        variant=""
                        className="btn-close"
                    >

                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {(props.accountInfo == null || props.isLoading) ? <ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /> :
                        (
                            <div className="row">
                                <div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
                                    <table className="table verticle-middle">
                                        <tr><td><b>First Name </b></td><td>: {props.accountInfo.info[0].first_name}</td></tr>
                                        <tr><td><b>Last Name </b></td><td>: {props.accountInfo.info[0].last_name}</td></tr>
                                        <tr><td><b>Vendor Name </b></td><td>: {props.accountInfo.info[0].vendor_name}</td></tr>
                                        <tr><td><b>Vendor Type </b></td><td>: {props.accountInfo.info[0].type}</td></tr>
                                        <tr><td><b>PAN Number </b></td><td>: {props.accountInfo.info[0].pan}</td></tr>
                                        <tr><td><b>GST Number </b></td><td>: {props.accountInfo.info[0].gst}</td></tr>
                                        <tr><td><b>Aadhar Number </b></td><td>: {props.accountInfo.info[0].aadhar_number}</td></tr>
                                        <tr><td><b>KYC Verified</b></td><td>: {props.accountInfo.info[0].kyc_verified}</td></tr>
                                        <tr><td><b>Mobile Number </b></td><td>: {props.accountInfo.info[0].mobile_number}</td></tr>
                                        <tr><td><b>Mobile Number 2</b></td><td>: {props.accountInfo.info[0].mobile_number_other}</td></tr>
                                        <tr><td><b>State </b></td><td>: {props.accountInfo.info[0].state}</td></tr>
                                        <tr><td><b>Country </b></td><td>: {props.accountInfo.info[0].country}</td></tr>
                                        <tr><td><b>IFSC Code </b></td><td>: {props.accountInfo.info[0].ifsc}</td></tr>
                                        <tr><td><b>Account Number </b></td><td>: {props.accountInfo.info[0].account_number}</td></tr>
                                        <tr><td><b>Contract Type </b></td><td>: {props.accountInfo.info[0].contract_type}</td></tr>
                                    </table>
                                </div>
                                <div className="table-responsive recentOrderTable col-md-6" style={{ height: "100%" }}>
                                    <table className="table verticle-middle">
                                        <tr><td><b>Contract Signed </b></td><td>: {props.accountInfo.info[0].contract_signed}</td></tr>
                                        <tr><td><b>Estimated Budget </b></td><td>: {props.accountInfo.info[0].estimated_budget}</td></tr>
                                        <tr><td><b>Final Budget </b></td><td>: {props.accountInfo.info[0].final_budget}</td></tr>
                                        <tr><td><b>Borading Date </b></td><td>: {props.accountInfo.info[0].boarding_date}</td></tr>
                                        <tr><td><b>Work Rating </b></td><td>: {props.accountInfo.info[0].work_rating}</td></tr>
                                        <tr><td><b>Planned Days </b></td><td>: {props.accountInfo.info[0].planned_days}</td></tr>
                                        <tr><td><b>Utilised Days </b></td><td>: {props.accountInfo.info[0].utilised_days}</td></tr>
                                        <tr><td><b>Last Updated </b></td><td>: {props.accountInfo.info[0].last_updated}</td></tr>
                                        <tr><td><b>Role </b></td><td>: {props.accountInfo.info[0].role_id}</td></tr>
                                        <tr><td><b>Amount Paid </b></td><td>: {props.accountInfo.info[0].actual_amount_paid}</td></tr>
                                        <tr><td><b>Bank Transfer </b></td><td>: {props.accountInfo.info[0].bank_transfer_amount}</td></tr>
                                        <tr><td><b>Cash payment </b></td><td>: {props.accountInfo.info[0].cash_transaction}</td></tr>
                                        <tr><td><b>Balance Amount </b></td><td>: {props.accountInfo.info[0].balance_amount}</td></tr>
                                        <tr><td><b>Email Id </b></td><td>: {props.accountInfo.info[0].email}</td></tr>

                                    </table>
                                </div>

                            </div>
                        )}
                </Modal.Body>
            </Modal>
    );
}
export default InfoModal;