import React, { useState } from 'react';
import Select from "react-select";
import { Axios, AxiosPost, convertNumberToWords } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import {ScaleLoader} from 'react-spinners'
import axios from 'axios';
import FileUpload from '../FileUpload/FileUpload';
import FileList from '../FileUpload/FileList/FileList'

function SalaryForm(props) {
    const initialFormData = { vendor_id: null, shoot_date: '', conveyance_charges: 0, agent_commission: 0, salary: 0, remarks: '', total_amount: '', transaction_mode: null }
    const [formData, setFormData] = useState(initialFormData);
    const transactionModeOptions = [{ value: "Cash", label: "Cash" }, { value: "Digital", label: "Digital" }, {value:"cash_advance",label:"Cash Advance"}]
    const [isLoading,setIsLoading]=useState(false);
    const [files, setFiles] = useState([]);
    const customStyles = {
        menu: () => ({
            zIndex:"5"
        })
      }

    const removeFile = (filename) => {
        setFiles(files.filter(file => file.name !== filename))
    }

    const onChangeHandler = (e) => {
        if(e.target.name=="conveyance_charges"){
            setFormData({...formData,[e.target.name]: e.target.value,"total_amount":formData.total_amount+convertstrtonum(e.target.value)-convertstrtonum(formData.conveyance_charges)})
        }
        else if(e.target.name=="agent_commission"){
            setFormData({...formData,[e.target.name]: e.target.value,"total_amount":formData.total_amount+convertstrtonum(e.target.value)-convertstrtonum(formData.agent_commission)})
        }
        else if(e.target.name=="salary"){
            setFormData({...formData,[e.target.name]: e.target.value,"total_amount":formData.total_amount+convertstrtonum(e.target.value)-convertstrtonum(formData.salary)})
        }
        else{
            
        setFormData({ ...formData, [e.target.name]: e.target.value});
        }
    }

    const convertstrtonum=(val)=>{
        return isNaN(parseInt(val))?0:parseInt(val)
    }

    const formSubmitHandler = async(e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const data = await AxiosPost('add_salary_trans.php', formData);
            if (data.success) {
                const form_data = new FormData();
                form_data.append("bill_id",data.bill_id)
                for (let i = 0; i < files.length; i++) {
                  form_data.append("files[]", files[i]);
                }
                console.log(form_data);
                const filuploadres=await axios.post('https://api.flickanalytics.in/FlickAPIs/file_upload.php', form_data, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
                console.log(filuploadres)
                setFiles([])
                pushNotify('success', "Success", 'Bill Raised Successfully');
                setFormData(initialFormData);
            }
            else pushNotify('error', "Error", data.error);

        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
            console.log(err)
        } finally{
            setIsLoading(false)
        }
    }
    

    return (
        <>
            {
                isLoading ? <div className="row mx-0" style={{ height:"500px" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
                    :
        <div className="basic-form">
            <form onSubmit={formSubmitHandler}>
                <div className="row">
                    <div className="form-group mb-3 col-md-6">
                        <label>Vendor Username <span style={{"color":"red"}}>*</span></label>
                        <Select
                            value={formData.vendor_id}
                            onChange={(e) => { setFormData({ ...formData, "vendor_id": e }) }}
                            options={props.vendorIdOptions}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Shoot Date <span style={{"color":"red"}}>*</span></label>
                        <input type="date" className="form-control" name='shoot_date' value={formData.shoot_date} onChange={onChangeHandler} />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Conveyance Charges</label>
                        <input type="number" min={0} className="form-control" name='conveyance_charges' value={formData.conveyance_charges} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.conveyance_charges)}</div>
                        
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Agent Commission</label>
                        <input type="number" min={0} className="form-control" name='agent_commission' value={formData.agent_commission} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.agent_commission)}</div>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Salary <span style={{"color":"red"}}>*</span></label>
                        <input type="number" min={0} className="form-control" name='salary' value={formData.salary} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.salary)}</div>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Total Amount <span style={{"color":"red"}}>*</span></label>
                        <input type="number" min={0} className="form-control" name='total_amount' value={formData.total_amount} onChange={onChangeHandler} />
                        <div style={{color:"green"}}>{convertNumberToWords(formData.total_amount)}</div>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Transaction Mode</label>
                        <Select
                            styles={customStyles}
                            value={formData.transaction_mode}
                            onChange={(e) => { setFormData({ ...formData, "transaction_mode": e }) }}
                            options={transactionModeOptions}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <label>Remarks</label>
                        <input type="text" className="form-control" name='remarks' value={formData.remarks} onChange={onChangeHandler} />
                    </div>
                                <div className='col-md-12'>
                                    <FileList files={files} removeFile={removeFile} />
                                </div>
                </div>
                                    <FileUpload files={files} setFiles={setFiles}
                                        removeFile={removeFile} />
                <button type="submit" className="btn btn-primary">
                    Add Expense
                </button>
            </form>
        </div>
}
</>
    );
}

export default SalaryForm;