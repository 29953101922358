import React, { Fragment,useState,useEffect } from "react";
import {Axios, AxiosGet} from "../../../context/UserContext";
import {pushNotify} from "../../../services/NotifyService";
import AccountsTable from "./components/AccountsTable";
import RegisterVendor from "./components/RegisterVendor";
import Stats from "./components/Stats";

const VendorAccounts = () => {
  const [accountsData,setAccountsData]=useState(null);
  const [isLoading,setIsLoading]=useState(false);

  const fetchAccountsData=async()=>{
    setIsLoading(true);
    try{
      const data = await AxiosGet('get_accounts_data.php');
      console.log(data)
      if(data.success){
        setAccountsData(data);
      }
      else{
        pushNotify("error","Error",data.msg);
      }
    }
    catch(err){
      pushNotify("error","Error","Server Error!");
    }
    finally{
      setIsLoading(false);
    }
    
  }

  useEffect(()=>{
    fetchAccountsData();
  },[])

  return (
    <Fragment>
      <Stats accountsData={accountsData} isLoading={isLoading}></Stats>
      <AccountsTable accountsData={accountsData} isLoading={isLoading} fetchAccountsData={fetchAccountsData}></AccountsTable>
      <RegisterVendor></RegisterVendor>
    </Fragment>
  );
};

export default VendorAccounts;
