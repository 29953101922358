import React, { Fragment, useState } from "react";
import { Dropdown } from "react-bootstrap";
import AddSchedule from "./components/AddSchedule";
import StatsTable from "./components/StatsTable";

const Schedules = () => {
  return (
    <Fragment>
      <StatsTable></StatsTable>
      <AddSchedule></AddSchedule>
    </Fragment>
  );
};

export default Schedules;
