import React from "react";
import { useState,useEffect } from "react";
import {pushNotify} from "../../../services/NotifyService"
import AllMovies from "./components/AllMovies";
import CreateMovie from "./components/CreateMovie";
import { Axios } from "../../../context/UserContext";

function Admin(){
    const [movies,setMovies]=useState(null);

    const getMovies=async ()=>{
        try{
            const {data}=await Axios.get("get_movies.php");
            setMovies(data.movies)
            console.log(data);
        }
        catch(err){
            pushNotify("error","Error","Server Error!")
            console.log(err)
        }
    }

    useEffect(()=>{
        getMovies();
    },[])

    return (
        localStorage.getItem("role")!="ADM_MASTER"?
        <center>Access Denied</center>:(
            <div className="row">
                <AllMovies movies={movies}></AllMovies>
                <CreateMovie getMovies={getMovies}></CreateMovie>
            </div>
        )
    );
}
export default Admin;