import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Axios, AxiosGet, AxiosPost } from "../../../context/UserContext";
import { pushNotify } from "../../../services/NotifyService";
import Stats from "./components/Stats";
import PropertyItems from "./components/PropertyItems";
import EquipmentItems from "./components/EquipmentItems";


const ItemMaster = () => {
	const [itemMasterData, setItemMasterData] = useState(null);
	const [deleteModal, setDeleteModal] = useState({ show: false, id: null });
	const [isLoading,setIsLoading]=useState(false);

	const fetchItemMasterData = async () => {
		setIsLoading(true);
		try {
			// const { data } = await Axios.get('getItemMasterData.php');
			const data=await AxiosGet('getItemMasterData.php');
			setItemMasterData(data);
			console.log(data);
		}
		catch (err) {
			pushNotify("error", "Error", "Server Error!");
			console.log(err)
		}
		finally{
			setIsLoading(false);
		}
	}

	useEffect(() => {
		fetchItemMasterData();
	}, []);

	const showDeleteForm = (index) => {
		setDeleteModal({ show: true, id: index });
	}

	const deleteItem = async () => {
		setIsLoading(true);
		try {
			const data =await AxiosPost('delete_item.php',{itemId:deleteModal.id})
			console.log(data);
			if (data.success) pushNotify('success', 'Success', 'Item Deleted Successfully');
			else pushNotify('error', 'Error', data.error);
			setDeleteModal({ show: false, id: null });
			await fetchItemMasterData();
			console.log(data);
		} catch (err) {
			pushNotify("error", "Error", "Server Error!");
			console.log(err)
		}
		finally{
			setIsLoading(false);
		}
	}

	const cancelDelete = () => {
		setDeleteModal({ show: false, id: null });
	}

	return (
		<Fragment>
			<Stats itemMasterData={itemMasterData} fetchItemMasterData={fetchItemMasterData}></Stats>
			<div className="row">
				<PropertyItems isLoading={isLoading} itemMasterData={itemMasterData} showDeleteForm={showDeleteForm} fetchItemMasterData={fetchItemMasterData}></PropertyItems>
				<EquipmentItems isLoading={isLoading} itemMasterData={itemMasterData} showDeleteForm={showDeleteForm} fetchItemMasterData={fetchItemMasterData}></EquipmentItems>
			</div>
			<Modal className="fade" show={deleteModal.show} centered>
				<Modal.Header>
					<Modal.Title>Delete Item?</Modal.Title>
					<Button
						onClick={cancelDelete}
						variant=""
						className="btn-close"
					>

					</Button>
				</Modal.Header>
				<Modal.Body>
					<h4>Are you sure you want to delete the item?</h4>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={deleteItem}
						variant="danger light"
					>
						Delete
					</Button>
					<Button variant="primary" onClick={cancelDelete}>Cancel</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default ItemMaster;
