import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { Axios, AxiosGet, AxiosPost, convertNumberToWords } from '../../../../context/UserContext';
import { pushNotify } from '../../../../services/NotifyService';
import CreatableSelect from 'react-select/creatable';
import { ScaleLoader } from "react-spinners";
import FileUpload from '../FileUpload/FileUpload';
import FileList from '../FileUpload/FileList/FileList'
import axios from 'axios';
function CashAdvanceForm(props) {
    const initialFormData = {
        vendor_id: null,
        shoot_date: '',
        remarks: '',
        total_amount: '',
        transaction_mode: null
    }
    const [formData, setFormData] = useState(initialFormData);
    const transactionModeOptions = [{ value: "Cash", label: "Cash" }, { value: "Digital", label: "Digital" }, { value: "cash_advance", label: "Cash Advance" }]
    const [isLoading, setIsLoading] = useState(false)
    const [files, setFiles] = useState([]);

    const removeFile = (filename) => {
        setFiles(files.filter(file => file.name !== filename))
    }

    const onChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (!Object.values(formData).every(val => {
                console.log(val)
                if (typeof (val) != 'object') {
                    if (val.trim() == '') return false;
                    else return true;
                }
                else {
                    if (val != null) return true;
                    else return false;
                }
            })) {
                pushNotify('error', 'Error', 'Please Fill in all Required Fields! in');
                return;
            }
            const data = await AxiosPost('add_advance_trans.php', formData);
            if (data.success) {
                const form_data = new FormData();
                form_data.append("bill_id", data.bill_id)
                for (let i = 0; i < files.length; i++) {
                    form_data.append("files[]", files[i]);
                }
                console.log(form_data);
                const filuploadres = await axios.post('https://api.flickanalytics.in/FlickAPIs/file_upload.php', form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log(filuploadres)
                setFiles([])
                pushNotify('success', "Success", 'Bill Raised Successfully');
                setFormData(initialFormData);
            }
            else { pushNotify('error', "Error", data.error) };

        } catch (err) {
            pushNotify("error", "Error", "Server Error!");
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            {
                isLoading ? <div className="row mx-0" style={{ height: "500px" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div>
                    :
                    <div className="basic-form">
                        <form onSubmit={formSubmitHandler} enctype="multipart/form-data">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Vendor Username <span style={{ "color": "red" }}>*</span></label>
                                    <Select
                                        value={formData.vendor_id}
                                        onChange={(e) => { setFormData({ ...formData, "vendor_id": e }) }}
                                        options={props.vendorIdOptions}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Shoot Date <span style={{ "color": "red" }}>*</span></label>
                                    <input type="date" className="form-control" name='shoot_date' id='shoot_date' onChange={onChangeHandler} value={formData.shoot_date} />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Total Amount <span style={{ "color": "red" }}>*</span></label>
                                    <input type="number" min={0} className="form-control" name='total_amount' id='total_amount' onChange={onChangeHandler} value={formData.total_amount} />
                                    <div style={{ color: "green" }}>{convertNumberToWords(formData.total_amount)}</div>
                                </div>
                                <div className="form-group mb-3 col-md-6" >
                                    <label>Transaction Mode <span style={{ "color": "red" }}>*</span></label>
                                    <Select
                                    style={{"position":"relative","z-index:":"5"}}
                                        value={formData.transaction_mode}
                                        onChange={(e) => { setFormData({ ...formData, "transaction_mode": e }) }}
                                        options={transactionModeOptions}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Remarks</label>
                                    <input type="text" className="form-control" name='remarks' id='remarks' onChange={onChangeHandler} value={formData.remarks} />
                                </div>
                                <div className='col-md-12'>
                                    <FileList files={files} removeFile={removeFile} />
                                </div>
                            </div>
                            
                            <FileUpload files={files} setFiles={setFiles} removeFile={removeFile} />
                            <button  type="submit" className="btn btn-primary">
                                Add Expense
                            </button>
                        </form>

                    </div>
            }
        </>

    );
}
export default CashAdvanceForm;