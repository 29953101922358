import React, { Fragment, useEffect, useState } from "react";
import Contacts from "./components/Contacts";
import Stats from "./components/Stats";
import {Axios, AxiosPost} from "../../../context/UserContext";
import {pushNotify} from "../../../services/NotifyService";

const Accounts = () => {
  const [accountsData,setAccountsData]=useState(null);
  const [isLoading,setIsLoading]=useState(false);

  const fetchAccountsData=async()=>{
    setIsLoading(true);
    try{
      const data = await AxiosPost('get_accounts_data.php');
      if(data.success){
        console.log(data);
        setAccountsData(data);
      }
      else{
        pushNotify("error","Error",data.msg);
      }
    }
    catch(err){
      console.log(err)
      pushNotify("error","Error","Server Error!");
    }
    finally{
      setIsLoading(false);
    }
    
  }

  useEffect(()=>{
    fetchAccountsData();
  },[])

  return (
    <Fragment>
      <Stats accountsData={accountsData}></Stats>
      <Contacts isLoading={isLoading} accountsData={accountsData} fetchAccountsData={fetchAccountsData}></Contacts>
    </Fragment>
  );
};

export default Accounts;
