import React, { useState, useEffect } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import { Axios, AxiosGet, AxiosPost, convertNumberToWords } from "../../../../context/UserContext";
import { pushNotify } from "../../../../services/NotifyService";
import { ScaleLoader } from "react-spinners";
import { Dropdown, Modal, Button } from "react-bootstrap";

const vendorTypeOptions = [
    { value: "Individual", label: "Individual" },
    { value: "Company", label: 'Company' }
]

const decisionOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
]

function EditModal(props) {
    const initialFormData = {
        vendor_id: props.editModalData.id,
        first_name: props.editModalData.first_name,
        last_name: props.editModalData.last_name,
        vendor_name: props.editModalData.vendor_name,
        vendor_type: { value: props.editModalData.type, label: props.editModalData.type },
        vendor_email: props.editModalData.email,
        vendor_role: { value: props.editModalData.role_id, label: props.editModalData.role_value },
        pan: props.editModalData.pan,
        gst: props.editModalData.gst,
        aadhar_number: props.editModalData.aadhar_number,
        kyc_verified: { value: props.editModalData.kyc_verified, label: props.editModalData.kyc_verified },
        mobile_number: props.editModalData.mobile_number,
        mobile_number_other: props.editModalData.mobile_number_other,
        country: props.editModalData.country,
        state: props.editModalData.state,
        ifsc: props.editModalData.ifsc,
        account_number: props.editModalData.account_number,
        contract_signed: { value: props.editModalData.contract_signed, label: props.editModalData.contract_signed },
        contract_type: props.editModalData.contract_type,
        estimated_budget: props.editModalData.estimated_budget,
        finalised_budget: props.editModalData.final_budget,
        boarding_date: props.editModalData.boarding_date,
        work_rating: props.editModalData.work_rating,
        planned_days: props.editModalData.planned_days,
        days_utilised: props.editModalData.utilised_days,
    }
    const [formData, setFormData] = useState(initialFormData);
    const [roleOptions, setRoleOptions] = useState([{ value: "-1", label: "Loading..." }]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchRoleOptions = async () => {
        const data = await AxiosGet('get_role_options.php');
        setRoleOptions(data.options);
    }

    useEffect(() => {
        fetchRoleOptions()
    }, [])

    const editVendorHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const data = await AxiosPost('edit_vendor.php', formData);
            if (data.success) {
                pushNotify("success", "Success", "Vendor Details updated successfully")
                props.setShowEditModal(false);
                props.setEditModalData(null);
            }
            else pushNotify("error", "Error", data.error)

        }
        catch (err) {
            pushNotify("error", "Error", "Server Error!")
        }
    }

    return (
        <Modal className="fade" show={props.showEditModal} size="xl" centered>
            <Modal.Header>
                <Modal.Title>Edit Account Details</Modal.Title>
                <Button
                    onClick={() => { props.setShowEditModal(false); props.setEditModalData(null) }}
                    variant=""
                    className="btn-close"
                >

                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className="basic-form">
                    {isLoading ? <div className="row mx-0" style={{ height: "100%" }}><ScaleLoader cssOverride={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }} /></div> :

                        <form onSubmit={(e) => editVendorHandler(e)}>
                            <div className="row">
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>First Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="first_name" value={formData.first_name} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Last Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="last_name" value={formData.last_name} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Vendor Type <span className="text-danger">*</span></label>
                                    <Select
                                        defaultValue={formData.vendor_type}
                                        onChange={(e) => setFormData({ ...formData, "vendor_type": e })}
                                        options={vendorTypeOptions}
                                    />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Vendor Role <span className="text-danger">*</span></label>
                                    <Select
                                        defaultValue={formData.vendor_role}
                                        onChange={(e) => setFormData({ ...formData, "vendor_role": e })}
                                        options={roleOptions}
                                    />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>PAN Number</label>
                                    <input type="text" className="form-control" id="pan" value={formData.pan} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>GST Number</label>
                                    <input type="text" className="form-control" id="gst" value={formData.gst} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Aadhar Number</label>
                                    <input type="number" className="form-control" id="aadhar_number" value={formData.aadhar_number} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>KYC Verified <span className="text-danger">*</span></label>
                                    <Select
                                        defaultValue={formData.kyc_verified}
                                        onChange={(e) => setFormData({ ...formData, "kyc_verified": e })}
                                        options={decisionOptions}
                                    />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Mobile Number <span className="text-danger">*</span></label>
                                    <input type="number" className="form-control" id="mobile_number" value={formData.mobile_number} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} required />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Other Contact Number</label>
                                    <input type="number" className="form-control" id="mobile_number_other" value={formData.mobile_number_other} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Country</label>
                                    <CountryDropdown
                                        classes="form-control"
                                        value={formData.country}
                                        onChange={(val) => setFormData({ ...formData, "country": val })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>State</label>
                                    <RegionDropdown
                                        classes="form-control"
                                        country={formData.country}
                                        value={formData.state}
                                        onChange={(val) => setFormData({ ...formData, "state": val })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>IFSC Code</label>
                                    <input type="text" className="form-control" id="ifsc" value={formData.ifsc} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Account Number</label>
                                    <input type="text" className="form-control" id="account_number" value={formData.account_number} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Contract Signed <span className="text-danger">*</span></label>
                                    <Select
                                        defaultValue={formData.contract_signed}
                                        onChange={(e) => setFormData({ ...formData, "contract_signed": e })}
                                        options={decisionOptions}
                                    />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Contract Type</label>
                                    <input type="text" className="form-control" id="contract_type" value={formData.contract_type} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Estimated Budget</label>
                                    <input type="number" className="form-control" id="estimated_budget" value={formData.estimated_budget} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                    <div style={{ color: "green" }}>{convertNumberToWords(formData.estimated_budget)}</div>
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Finalised Budget</label>
                                    <input type="number" className="form-control" id="finalised_budget" value={formData.finalised_budget} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                    <div style={{ color: "green" }}>{convertNumberToWords(formData.finalised_budget)}</div>
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Boarding Date</label>
                                    <input type="date" className="form-control" id="boarding_date" value={formData.boarding_date} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Work Rating</label>
                                    <input type="text" className="form-control" id="work_rating" value={formData.work_rating} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Planned Days</label>
                                    <input type="number" className="form-control" id="planned_days" value={formData.planned_days} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 col-xl-4 col-md-6">
                                    <label>Days Utilised</label>
                                    <input type="number" className="form-control" id="days_utilised" value={formData.days_utilised} onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })} />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Register Vendor
                            </button>
                        </form>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default EditModal;